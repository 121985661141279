import React, { Component } from "react";
import CartContext from "./CartContext";
import { SideMenu } from "./SideMenu";
import { BiRefresh } from "react-icons/bi";
import { BsFillPencilFill } from "react-icons/bs";
import { MessageBox } from "./MessageBox";
import { LoadSpinner } from "./LoadSpinner";
import Select from "react-select";
import { ManufacturingTask } from "./ManufacturingTask";
import { ManufacturingRawMaterials } from "./ManufacturingRawMaterials";

import {
  OverlayTrigger,
  Tabs,
  Tab,
  Tooltip,
  Button,
  ButtonToolbar,
  Form,
  Row,
  Col,
  Modal,
  Nav,
  Table,
  Navbar,
} from "react-bootstrap";

export class ManufacturingRequestOrders extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      manufacturingrequestorders: [],
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      reload: true,
      rawMaterialsInventory: [],
      firstload: false,
      users: [],
    };
  }
  GetUsers = () => {
    const config = {
      headers: { Authorization: `Bearer ${this.context.userData.bearertoken}` },
    };
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetStoreUserAccounts/" +
        this.context.storeId
      // config
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.setState({
            //users: data.filter((f) => f.storeRoleId != 1),
            users: data,
          });
        }
      })
      .catch((error) => {
        alert("Session Timeout! Signout and Signin again!");
      });
  };
  GetRawMaterialsInventories = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetRawMaterialsInventories/" +
        this.context.storeId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.setState({ rawMaterialsInventory: data }, () => {
            this.setState({ reload: false }, () => {
              this.setState({ reload: true });
            });
          });
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };
  GetManufacturingRequestOrders = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetManufacturingRequestOrders/" +
        this.context.storeId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.setState({ manufacturingrequestorders: data }, () => {
            this.setState({ reload: false }, () => {
              this.setState({ reload: true });
            });
          });
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  OnSaved = () => {
    this.OnRefreshButtonClick();
  };
  OnDeleted = () => {
    this.OnRefreshButtonClick();
  };

  OnRefreshButtonClick = () => {
    this.setState({ manufacturingrequestorders: [] }, () => {
      this.GetManufacturingRequestOrders();
    });
  };
  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };

  OnUpdated = (indexno) => {
    this.OnRefreshButtonClick();
  };

  addModalClose = () => {
    this.setState({ addModalShow: false }, () => {});
    this.OnRefreshButtonClick();
  };

  componentDidMount = () => {
    if (this.state.firstload == false) {
      this.state.firstload = true;
      this.GetManufacturingRequestOrders();
      this.GetRawMaterialsInventories();
      this.GetUsers();
    }
    window.scrollTo(0, 0);
  };

  render() {
    return (
      <>
        <div className="Container-fluid p-1 ">
          <Row>
            <Col className="" xs={2}>
              <SideMenu></SideMenu>
            </Col>
            <Col xs={10} className="pagebackground p-0">
              {this.state.msgboxOpen === true ? (
                <MessageBox
                  open={this.state.msgboxOpen}
                  title={this.state.msgboxTitle}
                  message={this.state.msgboxMessage}
                  callback={this.CloseMessagBoxCallback}
                />
              ) : (
                ""
              )}
              <Navbar
                expand="lg"
                className="secondarythemecolor position-sticky fixed-top  px-3"
              >
                <h4>
                  {" "}
                  &nbsp; &nbsp;Product Manufacturing RequestOrders(PMR)&nbsp;
                </h4>
                <Navbar.Collapse className="justify-content-end mx-0 ">
                  <Nav>
                    <ButtonToolbar className="text-end align-middle">
                      <Button
                        className="mainthemecolor"
                        // onClick={() => this.handleAddSupplier()}
                        onClick={() => this.setState({ addModalShow: true })}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="26"
                          height="26"
                          fill="currentColor"
                          class="bi bi-plus"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                        </svg>
                        Add
                      </Button>
                      &nbsp;
                      <Button
                        className="mainthemecolor"
                        size=""
                        onClick={() => this.OnRefreshButtonClick()}
                      >
                        <BiRefresh size="26"></BiRefresh>
                        Refresh
                      </Button>
                      &nbsp;
                      {this.state.addModalShow === true ? (
                        <ManufacturingAddOrEditModal
                          show={this.state.addModalShow}
                          Type="Add"
                          onHide={this.addModalClose}
                          rawMaterialsInventory={
                            this.state.rawMaterialsInventory
                          }
                          users={this.state.users}
                        />
                      ) : null}
                    </ButtonToolbar>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
              <div>
                <Tabs
                  id="controlled-tab-example"
                  activeKey={this.state.key}
                  onSelect={(k) => this.setState({ key: k })}
                  className="mb-3 bg-light fw-bold"
                  fill
                  style={{ fontSize: "22px", color: "yellow" }}
                >
                  <Tab eventKey="NewRequest" title="1.New Request">
                    <Table border striped className="px-0 bg-white ">
                      <thead className="">
                        <tr className="text-start text-black bg-white border">
                          <th></th>
                          <th width="10%">Created</th>
                          <th width="10%">PMR No</th>
                          <th width="30%">Product Name</th>
                          <th width="10%">Qty</th>
                          <th width="10%">Status</th>
                          <th width="10%">Created By</th>
                          <th width="10%">Assigned To</th>
                          <th width="10%" className="text-end">
                            Options
                          </th>
                        </tr>
                      </thead>
                      <tbody className="text-primary border">
                        {this.state.manufacturingrequestorders
                          .filter((f) => f.statusId == 1)
                          .sort((a, b) => (a.created > b.created ? 1 : -1))
                          .map((request, i) =>
                            this.state.reload === true ? (
                              <EditManufacturingInline
                                index={i}
                                manufacturingRequest={request}
                                OnDeleted={this.OnDeleted}
                                users={this.state.users}
                                rawMaterialsInventory={
                                  this.state.rawMaterialsInventory
                                }
                                Refresh={this.OnRefreshButtonClick}
                              ></EditManufacturingInline>
                            ) : null
                          )}
                      </tbody>
                    </Table>
                  </Tab>
                  <Tab eventKey="Making" title="2.Making" className="">
                    <Table border striped className="px-0 bg-white ">
                      <thead className="">
                        <tr className="text-start text-black bg-white border">
                          <th></th>
                          <th width="10%">Created</th>
                          <th width="10%">PMR No</th>
                          <th width="30%">Product Name</th>
                          <th width="10%">Qty</th>
                          <th width="10%">Status</th>
                          <th width="10%">Created By</th>
                          <th width="10%">Assigned To</th>
                          <th width="10%" className="text-end">
                            Options
                          </th>
                        </tr>
                      </thead>
                      <tbody className="text-primary border">
                        {this.state.manufacturingrequestorders
                          .filter((f) => f.statusId == 2)
                          .sort((a, b) => (a.created > b.created ? 1 : -1))
                          .map((request, i) =>
                            this.state.reload === true ? (
                              <EditManufacturingInline
                                index={i}
                                manufacturingRequest={request}
                                OnDeleted={this.OnDeleted}
                                users={this.state.users}
                                rawMaterialsInventory={
                                  this.state.rawMaterialsInventory
                                }
                                Refresh={this.OnRefreshButtonClick}
                              ></EditManufacturingInline>
                            ) : null
                          )}
                      </tbody>
                    </Table>
                  </Tab>
                  <Tab
                    eventKey="QualityCheck"
                    title="3.QualityCheck"
                    className=""
                  >
                    <Table border striped className="px-0 bg-white ">
                      <thead className="">
                        <tr className="text-start text-black bg-white border">
                          <th></th>
                          <th width="10%">Created</th>
                          <th width="10%">PMR No</th>
                          <th width="30%">Product Name</th>
                          <th width="10%">Qty</th>
                          <th width="10%">Status</th>
                          <th width="10%">Created By</th>
                          <th width="10%">Assigned To</th>
                          <th width="10%" className="text-end">
                            Options
                          </th>
                        </tr>
                      </thead>
                      <tbody className="text-primary border">
                        {this.state.manufacturingrequestorders
                          .filter((f) => f.statusId == 3)
                          .sort((a, b) => (a.created > b.created ? 1 : -1))
                          .map((request, i) =>
                            this.state.reload === true ? (
                              <EditManufacturingInline
                                index={i}
                                manufacturingRequest={request}
                                OnDeleted={this.OnDeleted}
                                users={this.state.users}
                                rawMaterialsInventory={
                                  this.state.rawMaterialsInventory
                                }
                                Refresh={this.OnRefreshButtonClick}
                              ></EditManufacturingInline>
                            ) : null
                          )}
                      </tbody>
                    </Table>
                  </Tab>
                  <Tab
                    eventKey="ReadyToShip"
                    title="4.ReadyToShip"
                    className=""
                  >
                    <Table border striped className="px-0 bg-white ">
                      <thead className="">
                        <tr className="text-start text-black bg-white border">
                          <th></th>
                          <th width="10%">Created</th>
                          <th width="10%">PMR No</th>
                          <th width="30%">Product Name</th>
                          <th width="10%">Qty</th>
                          <th width="10%">Status</th>
                          <th width="10%">Created By</th>
                          <th width="10%">Assigned To</th>
                          <th width="10%" className="text-end">
                            Options
                          </th>
                        </tr>
                      </thead>
                      <tbody className="text-primary border">
                        {this.state.manufacturingrequestorders
                          .filter((f) => f.statusId == 4)
                          .sort((a, b) => (a.created > b.created ? 1 : -1))
                          .map((request, i) =>
                            this.state.reload === true ? (
                              <EditManufacturingInline
                                index={i}
                                manufacturingRequest={request}
                                OnDeleted={this.OnDeleted}
                                users={this.state.users}
                                rawMaterialsInventory={
                                  this.state.rawMaterialsInventory
                                }
                                Refresh={this.OnRefreshButtonClick}
                              ></EditManufacturingInline>
                            ) : null
                          )}
                      </tbody>
                    </Table>
                  </Tab>
                  <Tab eventKey="History" title="5.History" className="">
                    <Table border striped className="px-0 bg-white ">
                      <thead className="">
                        <tr className="text-start text-black bg-white border">
                          <th></th>
                          <th width="10%">Created</th>
                          <th width="10%">PMR No</th>
                          <th width="30%">Product Name</th>
                          <th width="10%">Qty</th>
                          <th width="10%">Status</th>
                          <th width="10%">Created By</th>
                          <th width="10%">Assigned To</th>
                          <th width="10%" className="text-end">
                            Options
                          </th>
                        </tr>
                      </thead>
                      <tbody className="text-primary border">
                        {this.state.manufacturingrequestorders
                          .filter((f) => f.statusId == 5)
                          .sort((a, b) => (a.created > b.created ? 1 : -1))
                          .map((request, i) =>
                            this.state.reload === true ? (
                              <EditManufacturingInline
                                index={i}
                                manufacturingRequest={request}
                                OnDeleted={this.OnDeleted}
                                users={this.state.users}
                                rawMaterialsInventory={
                                  this.state.rawMaterialsInventory
                                }
                                Refresh={this.OnRefreshButtonClick}
                              ></EditManufacturingInline>
                            ) : null
                          )}
                      </tbody>
                    </Table>
                  </Tab>
                </Tabs>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
export class EditManufacturingInline extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      loadSpinnerOpen: false,
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      editModalShow: false,
    };
  }

  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };
  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };
  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  OnEditButtonClick = () => {
    this.setState({ editModalShow: true }, () => {});
  };

  OnDelete = () => {};

  editModalClose = () => {
    this.setState({ editModalShow: false }, () => {
      this.props.Refresh();
    });
  };
  GetProductNameById = (id) => {
    var result = this.context.prods.filter((f) => f.id == id);
    if (result.length > 0) return result[0].name;
    else return "";
  };
  GetStatusNameById = (id) => {
    var result = this.context.manufacturingStatusTypes.filter(
      (f) => f.id == id
    );
    if (result.length > 0) return result[0].name;
    else return "";
  };
  GetUserNameById = (id) => {
    var result = this.props.users.filter((f) => f.id == id);
    if (result.length > 0) return result[0].emailId;
    else return "";
  };
  componentDidMount = () => {
    this.setState({ supplier: this.props.supplier });
  };

  render() {
    return (
      <>
        {this.state.editModalShow === true ? (
          <ManufacturingAddOrEditModal
            show={this.state.editModalShow}
            onHide={this.editModalClose}
            manufacturingRequest={this.props.manufacturingRequest}
            Type="Edit"
            users={this.props.users}
            rawMaterialsInventory={this.props.rawMaterialsInventory}
          />
        ) : (
          ""
        )}
        {this.state.loadSpinnerOpen === true ? (
          <LoadSpinner open="true"></LoadSpinner>
        ) : (
          ""
        )}
        <tr
          className="text-start align-middle text-secondary bg-white mx-5"
          style={{ fontSize: "12px" }}
        >
          {this.state.msgboxOpen === true ? (
            <MessageBox
              open={this.state.msgboxOpen}
              title={this.state.msgboxTitle}
              message={this.state.msgboxMessage}
              callback={this.CloseMessagBoxCallback}
            />
          ) : (
            ""
          )}
          <td>{this.props.index + 1}</td>
          <td className="text-start ">
            {this.context.GetInDateFormat(
              new Date(this.props.manufacturingRequest.created)
            )}
          </td>
          <td className="text-start ">
            <b>{this.props.manufacturingRequest.pmrNo}</b>
          </td>
          <td className="text-start ">
            {this.GetProductNameById(this.props.manufacturingRequest.productId)}
          </td>
          <td className="text-start ">{this.props.manufacturingRequest.qty}</td>
          <td className="text-start ">
            {this.GetStatusNameById(this.props.manufacturingRequest.statusId)}
          </td>
          <td className="text-start ">
            {this.GetUserNameById(this.props.manufacturingRequest.createdBy)}
          </td>
          <td className="text-start ">
            {this.GetUserNameById(this.props.manufacturingRequest.assignedTo)}
          </td>
          <td className="col-md-2 text-end align-middle ">
            <div class="btn-group">
              <OverlayTrigger overlay={<Tooltip>Edit!</Tooltip>}>
                <Button
                  className="mainthemecolor"
                  onClick={(e) => {
                    this.OnEditButtonClick(e);
                  }}
                >
                  <BsFillPencilFill size="20" />
                </Button>
              </OverlayTrigger>
            </div>
          </td>
        </tr>
      </>
    );
  }
}
export class ManufacturingAddOrEditModal extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);

    this.state = {
      manufacturingRequest: {
        productId: "",
        qty: "1",
        customDetails: "",
        statusId: "1",
        statusWofklowDescription: "",
        createdBy: "",
        assignedTo: "",
      },
      previousStatusId:
        this.props.Type == "Edit"
          ? this.props.manufacturingRequest.statusId
          : "",
      firstload: false,
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      loadSpinnerOpen: false,
      productNames: [],

      manufacturingTasks: [],
      showManufacturingTaskModal: false,
      editManufacturingTaskRow: 0,
      editManufacturingTaskModalShow: false,

      manufacturingRawMaterials: [],
      showManufacturingRawMaterialModal: false,
      editManufacturingRawMaterialRow: 0,
      editManufacturingRawMaterialModalShow: false,
      nextStatusList: [{ state: "NewRequest", value: "1" }],
    };
  }
  LoadNextStatus = () => {
    if (this.state.manufacturingRequest.statusId == 1) {
      this.setState({
        nextStatusList: [
          { state: "NewRequest", value: "1" },
          { state: "MakingInProgress", value: "2" },
          { state: "Cancel", value: "6" },
        ],
      });
    } else if (this.state.manufacturingRequest.statusId == 2) {
      this.setState({
        nextStatusList: [
          { state: "MakingInProgress", value: "2" },
          { state: "MakingComplete", value: "3" },
          { state: "Cancel", value: "6" },
        ],
      });
    } else if (this.state.manufacturingRequest.statusId == 3) {
      this.setState({
        nextStatusList: [
          { state: "MakingComplete", value: "3" },
          { state: "QualityCheckComplete", value: "4" },
          { state: "MakingInProgress", value: "2" },
          { state: "Cancel", value: "6" },
        ],
      });
    } else if (this.state.manufacturingRequest.statusId == 4) {
      this.setState({
        nextStatusList: [
          { state: "QualityCheckComplete", value: "4" },
          { state: "ShippedToWarehouse", value: "5" },
          { state: "Cancel", value: "6" },
        ],
      });
    }
  };
  LoadProductName = () => {
    {
      var pd = [];
      this.context.prods.map((r) =>
        pd.push({
          value: r.id,
          label: r.name,
        })
      );
      this.setState({ productNames: pd });
    }
  };
  GetProductName = (id) => {
    var list = this.state.productNames.filter((f) => f.value == id);
    if (list.length > 0) return list[0].label;
    return "";
  };

  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
    this.props.onHide();
  };
  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };
  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };

  AddOrUpdateManufacturingRequestOrder = () => {
    this.ShowLoadSpinner();

    var tmp = this.state.manufacturingRequest;
    tmp.productManufacturingTasks = this.state.manufacturingTasks;
    tmp.productManufacturingRawMaterials = this.state.manufacturingRawMaterials;

    if (this.props.Type == "Add") {
      tmp.storeId = this.context.storeId;
      tmp.statusWofklowDescription =
        "<p>" +
        this.context.GetInDateFormat(new Date()) +
        "=> " +
        "NewRequest" +
        "</p>";
    } else {
      if (
        this.state.previousStatusId != this.state.manufacturingRequest.statusId
      ) {
        tmp.statusWofklowDescription +=
          "<p>" +
          this.context.GetInDateFormat(new Date()) +
          "=> " +
          this.context.manufacturingStatusTypes.filter(
            (d) => d.id == this.state.manufacturingRequest.statusId
          )[0].name +
          "</p>";
      }
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(tmp),
    };

    fetch(
      process.env.REACT_APP_API +
        "StoreManager/AddOrUpdateManufacturingRequestOrder/",
      requestOptions
    )
      .then((res) => {
        if (res.status == 200) {
          this.HideLoadSpinner();
          this.props.onHide();
          return;
        } else {
          alert("Error Code: " + res.status);
          this.HideLoadSpinner();
          return;
        }
      })
      .catch((error) => {
        this.HideLoadSpinner();
        alert("Exception. Received an Exception. Try after sometime !" + error);
      });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.AddOrUpdateManufacturingRequestOrder();
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;

    switch (name.trim()) {
      case "qty":
        this.state.manufacturingRequest.qty = value;
        break;
      case "customDetails":
        this.state.manufacturingRequest.customDetails = value;
        break;
      case "statusId":
        this.state.manufacturingRequest.statusId = value;
        break;
      case "statusWofklowDescription":
        this.state.manufacturingRequest.statusWofklowDescription = value;
        break;
      case "createdBy":
        this.state.manufacturingRequest.createdBy = value;
        break;
      case "assignedTo":
        this.state.manufacturingRequest.assignedTo = value;
        break;
    }
    this.setState({ manufacturingRequest: this.state.manufacturingRequest });
  };
  OnProductNameChange = (e) => {
    this.state.manufacturingRequest.productId = e.value;
    this.setState({ manufacturingRequest: this.state.manufacturingRequest });
  };

  UpdateManufacturingTask = (item, index) => {
    this.state.manufacturingTasks[index].taskName = item.taskName;
    this.state.manufacturingTasks[index].taskDescription = item.taskDescription;
    this.state.manufacturingTasks[index].assignedTo = item.assignedTo;
    this.state.manufacturingTasks[index].statusId = item.statusId;
    this.state.manufacturingTasks[index].seqNo = item.seqNo;

    this.setState(
      {
        manufacturingTasks: this.state.manufacturingTasks,
      },
      () => {
        this.setState({ editManufacturingTaskModalShow: false }, () => {});
      }
    );
  };

  AddManufacturingTask = (item) => {
    this.setState(
      {
        manufacturingTasks: [
          ...this.state.manufacturingTasks,
          {
            taskName: item.taskName,
            taskDescription: item.taskDescription,
            assignedTo: item.assignedTo,
            statusId: item.statusId,
            seqNo: this.state.manufacturingTasks.length + 1,
          },
        ],
      },
      () => {
        this.onHideManufacturingTaskModal();
      }
    );
  };
  onHideManufacturingTaskModal = () => {
    this.setState({ showManufacturingTaskModal: false });
  };

  handleManufacturingTaskAddItems = (e) => {
    this.setState({ showManufacturingTaskModal: true });
  };

  onHideManufacturingTaskEditModal = () => {
    this.setState({ editManufacturingTaskModalShow: false });
  };
  OnManufacturingTaskEditButtonClick = (e, i) => {
    this.setState({ editManufacturingTaskRow: i }, () => {
      this.setState({ editManufacturingTaskModalShow: true }, () => {});
    });
  };
  handleManufacturingTaskRemoveClick = (indexno) => {
    const list = [...this.state.manufacturingTasks];
    list.splice(indexno, 1);
    this.setState({ manufacturingTasks: list }, () => {});
  };

  UpdateManufacturingRawMaterial = (item, index) => {
    this.state.manufacturingRawMaterials[index].rawMaterialId =
      item.rawMaterialId;
    this.state.manufacturingRawMaterials[index].price = item.price;
    this.state.manufacturingRawMaterials[index].qty = item.qty;
    this.state.manufacturingRawMaterials[index].details = item.details;
    this.state.manufacturingRawMaterials[index].statusId = item.statusId;

    this.setState(
      {
        manufacturingRawMaterials: this.state.manufacturingRawMaterials,
      },
      () => {
        this.setState(
          { editManufacturingRawMaterialModalShow: false },
          () => {}
        );
      }
    );
  };

  AddManufacturingRawMaterial = (item) => {
    this.setState(
      {
        manufacturingRawMaterials: [
          ...this.state.manufacturingRawMaterials,
          {
            rawMaterialId: item.rawMaterialId,
            price: item.price,
            qty: item.qty,
            details: item.details,
            statusId: item.statusId,
          },
        ],
      },
      () => {
        this.onHideManufacturingRawMaterialModal();
      }
    );
  };
  onHideManufacturingRawMaterialModal = () => {
    this.setState({ showManufacturingRawMaterialModal: false });
  };

  handleManufacturingRawMaterialAddItems = (e) => {
    this.setState({ showManufacturingRawMaterialModal: true });
  };

  onHideManufacturingRawMaterialEditModal = () => {
    this.setState({ editManufacturingRawMaterialModalShow: false });
  };
  OnManufacturingRawMaterialEditButtonClick = (e, i) => {
    this.setState({ editManufacturingRawMaterialRow: i }, () => {
      this.setState({ editManufacturingRawMaterialModalShow: true }, () => {});
    });
  };
  handleManufacturingRawMaterialRemoveClick = (indexno) => {
    const list = [...this.state.manufacturingRawMaterials];
    list.splice(indexno, 1);
    this.setState({ manufacturingRawMaterials: list }, () => {});
  };
  GetRawMaterialName = (id) => {
    var list = this.props.rawMaterialsInventory.filter((f) => f.id == id);
    if (list.length > 0) return list[0].name;
    return "";
  };
  componentDidMount() {
    if (this.state.firstload == false) {
      this.state.firstload = true;
      if (this.state.productNames.length == 0) this.LoadProductName();
      if (this.props.Type == "Edit") {
        this.setState(
          {
            manufacturingRequest: JSON.parse(
              JSON.stringify(this.props.manufacturingRequest)
            ),
          },
          () => {
            this.LoadNextStatus();
          }
        );
        this.setState(
          {
            manufacturingTasks:
              this.props.manufacturingRequest.productManufacturingTasks.sort(
                (a, b) => (a.seqNo > b.seqNo ? 1 : -1)
              ),
          },
          () => {}
        );
        this.setState({
          manufacturingRawMaterials:
            this.props.manufacturingRequest.productManufacturingRawMaterials,
        });
      } else {
        this.state.manufacturingRequest.createdBy = this.context.userData.id;
        this.setState({
          manufacturingRequest: this.state.manufacturingRequest,
        });
      }
    }
    var d1 = document.getElementById("one");
    d1.innerHTML =
      this.props.manufacturingRequest != undefined
        ? this.props.manufacturingRequest.statusWofklowDescription
        : "";
  }
  GetUserNameById = (id) => {
    var result = this.props.users.filter((f) => f.id == id);
    if (result.length > 0) return result[0].emailId;
    else return "";
  };
  GetRawMaterialsTotalCost = () => {
    let sum = 0;
    this.state.manufacturingRawMaterials.forEach((v) => {
      sum += v.price * v.qty;
    });
    return sum;
  };
  OnChangeSeqNoOfTasks = (e, i) => {
    const { name, value } = e.target;
    this.state.manufacturingTasks[i].seqNo = value;
    this.setState({ manufacturingTasks: this.state.manufacturingTasks });
  };

  render() {
    return (
      <div className="container ">
        <Modal
          {...this.props}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="xl"
          backdrop="static"
          keyboard={false}
          dialogClassName="order-modal"
        >
          {this.state.msgboxOpen === true ? (
            <MessageBox
              open={this.state.msgboxOpen}
              title={this.state.msgboxTitle}
              message={this.state.msgboxMessage}
              callback={this.CloseMessagBoxCallback}
            />
          ) : (
            ""
          )}
          {this.state.loadSpinnerOpen === true ? (
            <LoadSpinner open="true"></LoadSpinner>
          ) : (
            ""
          )}
          <Form onSubmit={this.handleSubmit}>
            <Modal.Header closeButton className="bg-primary text-light">
              <Modal.Title id="contained-modal-title-vcenter">
                {this.props.Type == "Edit"
                  ? "Edit Product Manufacturing RequestOrder"
                  : "Add Product Manufacturing RequestOrder"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid ">
              {this.state.showManufacturingTaskModal == true ? (
                <>
                  <ManufacturingTask
                    onHide={this.onHideManufacturingTaskModal}
                    AddManufacturingTask={this.AddManufacturingTask}
                    users={this.props.users}
                    Type="Add"
                  ></ManufacturingTask>
                </>
              ) : (
                <></>
              )}
              {this.state.editManufacturingTaskModalShow == true ? (
                <>
                  <ManufacturingTask
                    onHide={this.onHideManufacturingTaskEditModal}
                    UpdateManufacturingTask={this.UpdateManufacturingTask}
                    Type="Edit"
                    users={this.props.users}
                    manufacturingTask={
                      this.state.manufacturingTasks[
                        this.state.editManufacturingTaskRow
                      ]
                    }
                    Index={this.state.editManufacturingTaskRow}
                  ></ManufacturingTask>
                </>
              ) : (
                <></>
              )}
              {this.state.showManufacturingRawMaterialModal == true ? (
                <>
                  <ManufacturingRawMaterials
                    onHide={this.onHideManufacturingRawMaterialModal}
                    AddManufacturingRawMaterial={
                      this.AddManufacturingRawMaterial
                    }
                    Type="Add"
                    rawMaterialsInventory={this.props.rawMaterialsInventory}
                  ></ManufacturingRawMaterials>
                </>
              ) : (
                <></>
              )}
              {this.state.editManufacturingRawMaterialModalShow == true ? (
                <>
                  <ManufacturingRawMaterials
                    onHide={this.onHideManufacturingRawMaterialEditModal}
                    UpdateManufacturingRawMaterial={
                      this.UpdateManufacturingRawMaterial
                    }
                    Type="Edit"
                    manufacturingRawMaterial={
                      this.state.manufacturingRawMaterials[
                        this.state.editManufacturingRawMaterialRow
                      ]
                    }
                    Index={this.state.editManufacturingRawMaterialRow}
                    rawMaterialsInventory={this.props.rawMaterialsInventory}
                  ></ManufacturingRawMaterials>
                </>
              ) : (
                <></>
              )}
              <Row
                className="m-2 p-2 bg-light border"
                style={{ fontSize: "9pt" }}
              >
                <Col md={6}>
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Form.Label column sm="3">
                      PMR No:
                    </Form.Label>
                    <Col sm="9">
                      <b>
                        <Form.Control
                          type="text"
                          size="lg"
                          className="pd-2 w-100"
                          name="pmrNo"
                          required
                          readOnly
                          value={this.state.manufacturingRequest.pmrNo}
                          placeholder=""
                          autoComplete="off"
                          onChange={(e) => this.handleItemsInputChange(e)}
                        />
                      </b>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Form.Label column sm="3">
                      Product Name
                    </Form.Label>
                    <Col sm="9">
                      <span id="productname">
                        <Select
                          name="productName"
                          autoFocus
                          value={{
                            value: this.state.manufacturingRequest.productId,
                            label: this.GetProductName(
                              this.state.manufacturingRequest.productId
                            ),
                          }}
                          onChange={(e) => this.OnProductNameChange(e)}
                          options={this.state.productNames}
                        ></Select>
                      </span>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Form.Label column sm="3">
                      Created By (*)
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        size="lg"
                        className="pd-2 w-100"
                        name="createdBy"
                        disabled
                        value={this.context.userData.emailId}
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      ></Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Form.Label column sm="3">
                      Assigned To (*)
                    </Form.Label>
                    <Col sm="9">
                      <Form.Select
                        type="text"
                        size="lg"
                        className="pd-2 w-100"
                        name="assignedTo"
                        value={this.state.manufacturingRequest.assignedTo}
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      >
                        <option value=""></option>
                        {this.props.users.map((d) => (
                          <>
                            <option value={d.id}>{d.emailId}</option>
                          </>
                        ))}
                      </Form.Select>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Form.Label column sm="3">
                      QTY (*)
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="number"
                        size="lg"
                        className="pd-2 w-100"
                        name="qty"
                        required
                        value={this.state.manufacturingRequest.qty}
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Form.Label column sm="3">
                      Status (*)
                    </Form.Label>
                    <Col sm="9">
                      <Form.Select
                        type="text"
                        size="lg"
                        className="pd-2 w-100"
                        name="statusId"
                        value={this.state.manufacturingRequest.statusId}
                        placeholder=""
                        required
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      >
                        {/* {this.context.manufacturingStatusTypes.map((d) => (
                          <option value={d.id}>{d.id + "." + d.name}</option>
                        ))} */}
                        {this.state.nextStatusList.map((d) => (
                          <option value={d.value}>
                            {d.value + "." + d.state}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Col sm="12">
                      Custom Requirement Details if any
                      <Form.Control
                        as="textarea"
                        type="textarea"
                        rows={11}
                        maxLength={2499}
                        className="pd-2 w-100"
                        name="customDetails"
                        value={this.state.manufacturingRequest.customDetails}
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Col sm="12">
                      StatusWorkflow (*)
                      <div id="one" className=""></div>
                      {/* <Form.Control
                        as="textarea"
                        type="textarea"
                        rows={11}
                        maxLength={999}
                        className="pd-2 w-100"
                        readOnly
                        name="statusWofklowDescription"
                        value={
                          this.state.manufacturingRequest
                            .statusWofklowDescription
                        }
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      /> */}
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="m-0">
                <Col md={8} className="text-start text-dark my-1">
                  <span>
                    <h4> Tasks: </h4>
                  </span>
                </Col>
                <Col md={4} className="text-end align-bottom">
                  <Button
                    variant="primary"
                    classname="px-2"
                    id="addbutton"
                    onClick={(e) => this.handleManufacturingTaskAddItems(e)}
                  >
                    {" "}
                    + Add
                  </Button>
                  &nbsp;&nbsp;
                </Col>
              </Row>
              <Row className="m-0 p-0 bg-light border">
                <Col>
                  <Table bordered size="sm" className=" text-start">
                    <thead className="bg-secondary text-white">
                      <tr size="lg" className="text-center">
                        <td></td>
                        <td width="20%">TaskName</td>
                        <td width="20%">TaskDescription</td>
                        <td width="20%">Assigned To</td>
                        <td width="10%">SeqNo</td>
                        <td width="20%">Status</td>
                        <td width="10%" className="text-center">
                          Options
                        </td>
                      </tr>
                    </thead>
                    <tbody size="lg">
                      {this.state.manufacturingTasks != undefined
                        ? this.state.manufacturingTasks
                            //.sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1))
                            .map((row, i) => (
                              <>
                                <tr
                                  height="30pt"
                                  className="align-middle text-center"
                                >
                                  <td className="text-center">{i + 1}</td>
                                  {/* <td>{row.sku}</td> */}
                                  <td className="text-start">{row.taskName}</td>
                                  <td>{row.taskDescription}</td>
                                  <td>
                                    {this.GetUserNameById(row.assignedTo)}
                                  </td>
                                  <td>
                                    {/* {row.seqNo} */}
                                    <Form.Control
                                      type="text"
                                      size="lg"
                                      className="pd-2 w-100 text-center"
                                      name="seqNo"
                                      required
                                      value={row.seqNo}
                                      placeholder=""
                                      autoComplete="off"
                                      onChange={(e) =>
                                        this.OnChangeSeqNoOfTasks(e, i)
                                      }
                                    />
                                  </td>
                                  <td>
                                    {row.statusId == 1
                                      ? "Open"
                                      : row.statusId == 2
                                      ? "InProgress"
                                      : row.statusId == 3
                                      ? "Complete"
                                      : ""}
                                  </td>
                                  <td className="text-center">
                                    <OverlayTrigger
                                      overlay={<Tooltip>Edit!</Tooltip>}
                                    >
                                      <Button
                                        className="mainthemecolor"
                                        onClick={(e) => {
                                          this.OnManufacturingTaskEditButtonClick(
                                            e,
                                            i
                                          );
                                        }}
                                      >
                                        <BsFillPencilFill size="20" />
                                      </Button>
                                    </OverlayTrigger>
                                    &nbsp;&nbsp;
                                    <OverlayTrigger
                                      overlay={<Tooltip>Delete!</Tooltip>}
                                    >
                                      <Button
                                        variant="outline-danger"
                                        onClick={(e) => {
                                          this.handleManufacturingTaskRemoveClick(
                                            i
                                          );
                                        }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          class="bi bi-trash"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                          <path
                                            fill-rule="evenodd"
                                            d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                          />
                                        </svg>
                                      </Button>
                                    </OverlayTrigger>
                                  </td>
                                </tr>
                              </>
                            ))
                        : ""}
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <br />
              <Row className="m-0">
                <Col md={8} className="text-start text-dark my-1">
                  <span>
                    <h4> RawMaterials: </h4>
                  </span>
                </Col>
                <Col md={4} className="text-end align-bottom">
                  <Button
                    variant="primary"
                    classname="px-2"
                    id="addbutton"
                    onClick={(e) =>
                      this.handleManufacturingRawMaterialAddItems(e)
                    }
                  >
                    + Add
                  </Button>
                  &nbsp;&nbsp;
                </Col>
              </Row>
              <Row className="m-0 p-0 bg-light border">
                <Col>
                  <Table bordered size="sm" className=" text-start">
                    <thead className="bg-secondary text-white">
                      <tr size="lg" className="text-center">
                        <td></td>
                        <td width="30%">RawMaterial Name</td>
                        <td width="10%">Price</td>
                        <td width="10%">Qty</td>
                        <td width="10%">
                          SubTotal <br />
                          [Rs.{this.GetRawMaterialsTotalCost()}]
                        </td>
                        <td width="20%">Details</td>
                        <td width="10%">Status</td>
                        <td width="10%" className="text-center">
                          Options
                        </td>
                      </tr>
                    </thead>
                    <tbody size="lg">
                      {this.state.manufacturingRawMaterials != undefined
                        ? this.state.manufacturingRawMaterials
                            //.sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1))
                            .map((row, i) => (
                              <>
                                <tr
                                  height="30pt"
                                  className="align-middle text-center"
                                >
                                  <td className="text-center">{i + 1}</td>

                                  <td className="text-start">
                                    {this.GetRawMaterialName(row.rawMaterialId)}
                                  </td>
                                  <td>{row.price}</td>
                                  <td>{row.qty}</td>
                                  <td>
                                    <b>{row.price * row.qty}</b>
                                  </td>
                                  <td>{row.details}</td>
                                  <td>
                                    {row.statusId == 1
                                      ? "Requested"
                                      : row.statusId == 2
                                      ? "Delivered"
                                      : row.statusId == 3
                                      ? "OutOfStock"
                                      : ""}
                                  </td>

                                  <td className="text-center">
                                    <OverlayTrigger
                                      overlay={<Tooltip>Edit!</Tooltip>}
                                    >
                                      <Button
                                        className="mainthemecolor"
                                        onClick={(e) => {
                                          this.OnManufacturingRawMaterialEditButtonClick(
                                            e,
                                            i
                                          );
                                        }}
                                      >
                                        <BsFillPencilFill size="20" />
                                      </Button>
                                    </OverlayTrigger>
                                    &nbsp;&nbsp;
                                    <OverlayTrigger
                                      overlay={<Tooltip>Delete!</Tooltip>}
                                    >
                                      <Button
                                        variant="outline-danger"
                                        onClick={(e) => {
                                          this.handleManufacturingRawMaterialRemoveClick(
                                            i
                                          );
                                        }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          class="bi bi-trash"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                          <path
                                            fill-rule="evenodd"
                                            d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                          />
                                        </svg>
                                      </Button>
                                    </OverlayTrigger>
                                  </td>
                                </tr>
                              </>
                            ))
                        : ""}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer className="bg-light">
              <Form.Group>
                <Button variant="primary" size="lg" type="submit">
                  Save
                </Button>
              </Form.Group>

              <Button variant="danger" size="lg" onClick={this.props.onHide}>
                Close
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }
}
