import React, { Component } from "react";
import CartContext from "./CartContext";
import { MessageBox } from "./MessageBox";
import { LoadSpinner } from "./LoadSpinner";
import Select from "react-select";
import reactSelect from "react-select";
import axios from "axios";
import Switch from "react-switch";

import {
  FloatingLabel,
  OverlayTrigger,
  Tooltip,
  Table,
  InputGroup,
  Modal,
  Button,
  Row,
  Col,
  Form,
  Image,
  Alert,
} from "react-bootstrap";

// https://www.geeksforgeeks.org/how-to-create-an-editable-table-with-add-delete-and-search-filter-using-reactjs/
export class ProductAddOrEditModal extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);

    this.state = {
      prod: {
        hideMe: false,
        deliveryCostIncluded: false,
        hideWhenOutOfStock: true,
        productVariantType: 1,
        takeAwayChargeInPercentage: 0,
        homeDeliveryChargeInPercentage: 0,
        mrp: 0,
        discount: 0,
      },
      mainCategoryId: "",
      sellingPrice: 0,
      productImages: [],
      productMultiVariants: [],
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      loadSpinnerOpen: false,
      ProductDataType: [],
      ProductDataTypeValues: [],
      images: [
        // { value: "chocolate", label: "Chocolate" },
        // { value: "strawberry", label: "Strawberry" },
        // { value: "vanilla", label: "Vanilla" },
      ],
      selectedOption: null,
    };
  }

  LoadImages = () => {
    {
      var timages = [];

      this.context.images.map((r) =>
        timages.push({
          value: r.name,
          label: r.name,
        })
      );
      this.setState({ images: timages });
    }
  };
  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
    this.props.onHide();
  };
  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };
  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );
  };

  AddProductInDB = () => {
    if (
      this.state.prod.productVariantType == 2 &&
      this.state.productMultiVariants.length > 0
    ) {
      this.state.prod.mrp = this.state.productMultiVariants[0].mrp;
      this.state.prod.discount = this.state.productMultiVariants[0].discount;
    }

    this.ShowLoadSpinner();
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        storeId: this.context.storeId,
        name: this.state.prod.name,
        name2: this.state.prod.name2,
        productVariantType: this.state.prod.productVariantType,
        customField1: this.state.prod.customField1,
        customField2: this.state.prod.customField2,
        customField3: this.state.prod.customField3,
        sku: this.state.prod.sku,
        brandId: this.state.prod.brandId,
        description: this.state.prod.description,
        relatedProductsSkuListByCsv:
          this.state.prod.relatedProductsSkuListByCsv,
        description2FileName: this.state.prod.description2FileName,
        subCategoryId: this.state.prod.subCategoryId,
        mrp: this.state.prod.mrp,
        discount: this.state.prod.discount,
        inStockQty: this.state.prod.inStockQty,
        weightVariant: this.state.prod.weightVariant,
        hsncode: this.state.prod.hsncode,
        gst: this.state.prod.gst,
        cess: this.state.prod.cess,
        hideMe: this.state.prod.hideMe,
        hideWhenOutOfStock: this.state.prod.hideWhenOutOfStock,
        productImages: this.state.productImages,
        productCustomFields:
          this.state.prod.productVariantType == 2 &&
          this.state.productMultiVariants.length > 0
            ? this.state.productMultiVariants.sort((a, b) =>
                a.seqNo > b.seqNo ? 1 : -1
              )
            : undefined,
      }),
    };

    fetch(
      process.env.REACT_APP_API + "StoreManager/AddProduct/",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        this.context.GetAllProducts(); //TODO. it should be removed
        this.HideLoadSpinner();
        this.ShowMessagBox("Information", "Added Successfully");
      })
      .catch((error) => {
        this.HideLoadSpinner();
        this.ShowMessagBox("Failed", "Unable to Add! try later");
        alert("Failed" + error);
      });
  };
  UpdateProductInDB = () => {
    this.ShowLoadSpinner();
    var tmpProd = this.props.prod;

    if (
      this.state.prod.productVariantType == 2 &&
      this.state.productMultiVariants.length > 0
    ) {
      this.state.prod.mrp = this.state.productMultiVariants[0].mrp;
      this.state.prod.discount = this.state.productMultiVariants[0].discount;
      tmpProd.productCustomFields = this.state.productMultiVariants.sort(
        (a, b) => (a.seqNo > b.seqNo ? 1 : -1)
      );
    }

    tmpProd.name = this.state.prod.name;
    tmpProd.name2 = this.state.prod.name2;
    tmpProd.productVariantType = this.state.prod.productVariantType;
    tmpProd.customField1 = this.state.prod.customField1;
    tmpProd.customField2 = this.state.prod.customField2;
    tmpProd.customField3 = this.state.prod.customField3;
    tmpProd.sku = this.state.prod.sku;
    tmpProd.brandId = this.state.prod.brandId;
    tmpProd.description = this.state.prod.description;
    tmpProd.relatedProductsSkuListByCsv =
      this.state.prod.relatedProductsSkuListByCsv;
    tmpProd.description2FileName = this.state.prod.description2FileName;
    tmpProd.subCategoryId = this.state.prod.subCategoryId;
    tmpProd.mrp = this.state.prod.mrp;
    tmpProd.discount = this.state.prod.discount;
    tmpProd.inStockQty = this.state.prod.inStockQty;
    tmpProd.weightVariant = this.state.prod.weightVariant;
    tmpProd.hsncode = this.state.prod.hsncode;
    tmpProd.gst = this.state.prod.gst;
    tmpProd.cess = this.state.prod.cess;
    tmpProd.hideMe = this.state.prod.hideMe;
    tmpProd.hideWhenOutOfStock = this.state.prod.hideWhenOutOfStock;
    // tmpProd.modified = new Date().toLocaleString();
    tmpProd.productImages = this.state.productImages;

    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(tmpProd),
    };

    fetch(
      process.env.REACT_APP_API +
        "StoreManager/UpdateProduct/" +
        this.props.prod.id,
      requestOptions
    )
      .then((res) => {
        if (res.status === 200) {
          //TODO
          this.context.GetAllProducts();
          this.HideLoadSpinner();
          this.ShowMessagBox("Information", "Updated Successfully");
        } else {
          this.HideLoadSpinner();
          this.ShowMessagBox("Failed", "Not Updated");
        }
      })
      .catch((error) => {
        this.HideLoadSpinner();
        this.ShowMessagBox("Failed", "Not Updated");
      });
  };

  ValidateForm = () => {
    if (this.state.mainCategoryId.trim() == "") {
      alert("Please select MainCategory!");
      return false;
    }
    if (this.state.prod.subCategoryId == 0) {
      alert("Please select SubCategory!");
      return false;
    }

    if (this.state.prod.productVariantType == 2) {
      if (
        (this.state.prod.customField1 == "" ||
          this.state.prod.customField1 == undefined) &&
        (this.state.prod.customField2 == "" ||
          this.state.prod.customField2 == undefined) &&
        (this.state.prod.customField3 == "" ||
          this.state.prod.customField3 == undefined)
      ) {
        alert("configure atleast 1 Custom Fields");
        return false;
      }
      if (this.state.productMultiVariants.length == 0) {
        alert("Add Product Multivariants!");
        return false;
      }
    }
  };

  handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    if (this.ValidateForm() === false) {
      event.stopPropagation();
      return;
    }

    if (this.props.Type == "Edit") {
      this.UpdateProductInDB();
    }
    if (this.props.Type == "Add") this.AddProductInDB();
  };

  handleAddImages = (e) => {
    this.setState({
      productImages: [
        ...this.state.productImages,
        {
          storeId: this.context.storeId,
          fileName: "",
          seqNo: this.state.productImages.length + 1,
        },
      ],
    });
  };
  handleAddProductMultiVariants = (e) => {
    if (
      (this.state.prod.customField1 == "" ||
        this.state.prod.customField1 == undefined) &&
      (this.state.prod.customField2 == "" ||
        this.state.prod.customField2 == undefined)
      //   &&
      // (this.state.prod.customField3 == "" ||
      //   this.state.prod.customField3 == undefined)
    )
      alert("select atleast one Custom Field before adding a row!");
    this.setState({
      productMultiVariants: [
        ...this.state.productMultiVariants,
        {
          customValue1: "",
          customValue2: "",
          customValue3: "",
          seqNo: this.state.productMultiVariants.length + 1,
          mrp: 0,
          discount: 0,
          inStockQty: 0,
          storeId: this.context.storeId,
        },
      ],
    });
  };
  handleImageSelectionChange = (e, index) => {
    //const { name, value } = e.target;
    var name = "fileName";
    const list = [...this.state.productImages];
    list[index][name] = e.value;
    this.setState({ productImages: list });
  };

  handlePosterSelectionChange = (e, index) => {
    //const { name, value } = e.target;
    var name = "posterName";
    const list = [...this.state.productImages];
    list[index][name] = e.value;
    this.setState({ productImages: list });
  };

  IsDuplicateProductVariants = (index, name, value) => {
    if (name == "customValue1") {
      var result = this.state.productMultiVariants.filter(
        (f) =>
          f.customValue1 == value &&
          f.customValue2 == this.state.productMultiVariants[index].customValue2
      );
      if (result.length > 0) return true;
    }
    if (name == "customValue2") {
      var result = this.state.productMultiVariants.filter(
        (f) =>
          f.customValue1 ==
            this.state.productMultiVariants[index].customValue1 &&
          f.customValue2 == value
      );
      if (result.length > 0) return true;
    }
    return false;
  };

  handleProductMultivariantChange = (e, index) => {
    const { name, value } = e.target;
    if (this.IsDuplicateProductVariants(index, name, value) == true) {
      alert("Duplicate entry! Same row already exist!");
      return;
    }
    const list = [...this.state.productMultiVariants];
    list[index][name] = value;
    this.setState({
      productMultiVariants: list,
    });
  };

  handleImageseqNoChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...this.state.productImages];
    list[index][name] = value;
    this.setState({ productImages: list });
  };

  handleRemoveClick = (i) => {
    const list = [...this.state.productImages];
    list.splice(i, 1);
    this.setState({ productImages: list });
  };
  handleRemoveProductMultiVariantClick = (i) => {
    const list = [...this.state.productMultiVariants];
    list.splice(i, 1);
    this.setState({ productMultiVariants: list });
  };

  Image2Change = (e) => {
    this.state.prod.image2 = e.value;
    this.setState({ prod: this.state.prod }, () => {});
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;

    //validate for minus values not to allow
    switch (name.trim()) {
      case "name":
        this.state.prod.name = value;
        break;
      case "name2":
        this.state.prod.name2 = value;
        break;
      case "customField1":
        this.state.prod.customField1 = value;
        break;
      case "customField2":
        this.state.prod.customField2 = value;
        break;
      case "customField3":
        this.state.prod.customField3 = value;
        break;
      case "productVariantType":
        this.state.prod.productVariantType = value;
        break;
      case "sku":
        this.state.prod.sku = value;
        break;
      case "brandId":
        // if (value.trim() == "0")
        //   this.state.prod.brandId = "00000000-0000-0000-0000-000000000000";
        //else
        this.state.prod.brandId = value;
        break;
      case "description":
        this.state.prod.description = value;
        break;
      case "relatedProductsSkuListByCsv":
        this.state.prod.relatedProductsSkuListByCsv = value;
        break;

      case "description2FileName":
        this.state.prod.description2FileName = value;
        break;
      case "mrp":
        this.state.prod.mrp = value;
        break;
      case "takeAwayChargeInPercentage":
        this.state.prod.takeAwayChargeInPercentage = value;
        break;
      case "homeDeliveryChargeInPercentage":
        this.state.prod.homeDeliveryChargeInPercentage = value;
        break;
      case "discount":
        this.state.prod.discount = value;
        break;
      case "inStockQty":
        this.state.prod.inStockQty = value;
        break;
      case "weightVariant":
        this.state.prod.weightVariant = value;
        break;

      case "hsncode":
        this.state.prod.hsncode = value;
        break;
      case "gst":
        this.state.prod.gst = value;
        break;
      case "cess":
        this.state.prod.cess = value;
        break;
      case "hideMe":
        this.state.prod.hideMe = value;
        break;
      case "hideWhenOutOfStock":
        this.state.prod.hideWhenOutOfStock = value;
        break;
      case "subCategoryId":
        this.state.prod.subCategoryId = value;
        break;
    }
    this.setState({ prod: this.state.prod }, () => {
      this.updateSellingPrice();
    });
  };
  updateSellingPrice = () => {
    this.setState({
      sellingPrice:
        Number(this.state.prod.mrp) -
        (Number(this.state.prod.mrp) * Number(this.state.prod.discount)) / 100,
    });
  };

  onMainCategorySelectChangeEvent = (e) => {
    this.setState({ mainCategoryId: e.target.value }, () => {
      this.state.prod.subCategoryId = 0;
      this.setState({ prod: this.state.prod });
    });
  };

  GetProductDataType = () => {
    var res = axios
      .get(
        process.env.REACT_APP_API +
          "StoreManager/GetProductDataType/" +
          this.context.storeId
      )
      .then((response) => {
        if (response.status === 200) {
          this.setState({ ProductDataType: response.data });
        }
      })
      .catch((error) => {
        console.error("GetProductDataType - There was an error! ", error);
      });
  };
  GetProductDataTypeValues = () => {
    var res = axios
      .get(
        process.env.REACT_APP_API +
          "StoreManager/GetAllProductDataTypeValues/" +
          this.context.storeId
      )
      .then((response) => {
        if (response.status === 200) {
          this.setState({ ProductDataTypeValues: response.data });
        }
      })
      .catch((error) => {
        console.error("GetProductDataTypeValues - There was an error! ", error);
      });
  };
  handleHidemeChange = (checked) => {
    this.state.prod.hideMe = checked == false ? true : false;
    this.setState({ prod: this.state.prod });
  };
  handleDeliveryPriceIncluded = (checked) => {
    this.state.prod.deliveryCostIncluded = checked == true ? true : false;
    this.setState({ prod: this.state.prod });
  };
  handleHidemeWhenOutOfStockChange = (checked) => {
    this.state.prod.hideWhenOutOfStock = checked == false ? true : false;
    this.setState({ prod: this.state.prod });
  };
  handleWeightVariantChange = (checked) => {
    this.state.prod.weightVariant = checked == true ? true : false;
    this.setState({ prod: this.state.prod });
  };

  componentDidMount() {
    this.GetProductDataType();
    this.GetProductDataTypeValues();
    if (this.state.images.length == 0) this.LoadImages();

    if (this.props.Type == "Edit") {
      this.state.prod = this.props.prod;

      this.setState({
        sellingPrice:
          this.props.prod.mrp -
          (this.props.prod.mrp * this.props.prod.discount) / 100,
      });

      this.setState({ mainCategoryId: this.props.mainCategoryId });
      this.setState({ productImages: this.props.prod.productImages });
      if (this.props.prod.productCustomFields) {
        this.setState({
          productMultiVariants: this.props.prod.productCustomFields.sort(
            (a, b) => (a.seqNo > b.seqNo ? 1 : -1)
          ),
        });
      }
    }
  }

  render() {
    return (
      <div className="container ">
        <Modal
          {...this.props}
          // size="lg"
          // aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
          // dialogClassName="ProductModal"
          dialogClassName="order-summary-mobile"
        >
          {this.state.msgboxOpen === true ? (
            <MessageBox
              open={this.state.msgboxOpen}
              title={this.state.msgboxTitle}
              message={this.state.msgboxMessage}
              callback={this.CloseMessagBoxCallback}
            />
          ) : (
            ""
          )}
          {this.state.loadSpinnerOpen === true ? (
            <LoadSpinner open="true"></LoadSpinner>
          ) : (
            ""
          )}
          <Form onSubmit={this.handleSubmit}>
            <Modal.Header closeButton className="bg-warning" size="xl">
              <Modal.Title id="contained-modal-title-vcenter">
                {this.props.Type == "Edit" ? "Edit Product" : "Add Product"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid ">
              <div
                className="fixedTableHightOrder"
                style={{
                  height: window.innerHeight - 175 + "px",
                }}
              >
                <Row
                  className="m-2 p-2 bg-light border"
                  style={{ fontSize: "9pt" }}
                >
                  <Col md={4}>
                    <Form.Group as={Row} className="mb-1 " controlId="">
                      <Form.Label column sm="4">
                        Product Name - English(*)
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          as="textarea"
                          type="text"
                          rows={2}
                          name="name"
                          maxLength={119}
                          value={this.state.prod.name}
                          required
                          placeholder=""
                          autoComplete="off"
                          onChange={(e) => this.handleInputChange(e)}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-1 " controlId="">
                      <Form.Label column sm="4">
                        Product Name (in 2nd Language)
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          as="textarea"
                          type="text"
                          rows={2}
                          name="name2"
                          maxLength={119}
                          value={this.state.prod.name2}
                          placeholder=""
                          autoComplete="off"
                          onChange={(e) => this.handleInputChange(e)}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-1" controlId="">
                      <Form.Label column sm="4">
                        Main Category (*)
                      </Form.Label>
                      <Col sm="8">
                        <Form.Select
                          type="text"
                          name="mainCategoryId"
                          value={this.state.mainCategoryId}
                          required
                          placeholder="select Main Category"
                          autoComplete="off"
                          onChange={(e) =>
                            this.onMainCategorySelectChangeEvent(e)
                          }
                        >
                          <option value={0}></option>
                          {this.context.mainCategories.map((mainCategory) => (
                            <option value={mainCategory.id}>
                              {mainCategory.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-1" controlId="">
                      <Form.Label column sm="4">
                        Sub Category (*)
                      </Form.Label>
                      <Col sm="8">
                        <Form.Select
                          type="text"
                          name="subCategoryId"
                          value={this.state.prod.subCategoryId}
                          required
                          placeholder=""
                          autoComplete="off"
                          onChange={(e) => this.handleInputChange(e)}
                        >
                          <option value={0}></option>
                          {this.context.subCategories
                            .filter(
                              (subcategory) =>
                                subcategory.mainCategoryId ===
                                this.state.mainCategoryId
                            )
                            .map((subcategory) => (
                              <option value={subcategory.id}>
                                {subcategory.name}
                              </option>
                            ))}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-1" controlId="">
                      <Form.Label column sm="4">
                        Brand Name
                      </Form.Label>
                      <Col sm="8">
                        <Form.Select
                          name="brandId"
                          value={this.state.prod.brandId}
                          placeholder="select Brand"
                          autoComplete="off"
                          onChange={(e) => this.handleInputChange(e)}
                        >
                          <option value={undefined}></option>
                          {this.context.brands.map((brand) => (
                            <option value={brand.id}>{brand.brandName}</option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                    {/* <Form.Group as={Row} className="mb-1" controlId="">
                      <Form.Label column sm="4">
                        HSN Code
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          type="number"
                          min={0}
                          name="hsncode"
                          value={this.state.prod.hsncode}
                          placeholder=""
                          autoComplete="off"
                          onChange={(e) => this.handleInputChange(e)}
                        />
                      </Col>
                    </Form.Group> */}
                    <Form.Group as={Row} className="mb-1" controlId="">
                      <Form.Label column sm="4">
                        GST (%)
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          type="number"
                          min={0}
                          name="gst"
                          value={this.state.prod.gst}
                          placeholder=""
                          autoComplete="off"
                          onChange={(e) => this.handleInputChange(e)}
                        />
                      </Col>
                      <Form.Label column sm="2">
                        CESS (%)
                      </Form.Label>
                      <Col sm="2">
                        <Form.Control
                          type="number"
                          min={0}
                          name="cess"
                          value={this.state.prod.cess}
                          placeholder=""
                          autoComplete="off"
                          onChange={(e) => this.handleInputChange(e)}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-1" controlId="">
                      <Form.Label column sm="4">
                        ShowMe On Portal?
                      </Form.Label>
                      <Col sm="8">
                        <Switch
                          onChange={this.handleHidemeChange}
                          checked={!this.state.prod.hideMe}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-1" controlId="">
                      <Form.Label column sm="4">
                        ShowMe when Out-Of-Stock
                      </Form.Label>
                      <Col sm="8">
                        <Switch
                          onChange={this.handleHidemeWhenOutOfStockChange}
                          checked={!this.state.prod.hideWhenOutOfStock}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    {window.innerWidth > 1200 ? (
                      <>
                        <Form.Group as={Row} className="mb-1 " controlId="">
                          <Form.Label column sm="4">
                            Product Variant Type
                          </Form.Label>
                          <Col sm="8">
                            <Form.Select
                              name="productVariantType"
                              value={this.state.prod.productVariantType}
                              autoComplete="off"
                              onChange={(e) => this.handleInputChange(e)}
                            >
                              <option value={1}>Single</option>
                              <option value={2}>Multiple</option>
                            </Form.Select>
                          </Col>
                        </Form.Group>
                      </>
                    ) : (
                      <></>
                    )}

                    {this.state.prod.productVariantType == 1 ? (
                      <>
                        <Form.Group as={Row} className="mb-1 " controlId="">
                          <Form.Label column sm="4">
                            SKU
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              type="text"
                              name="sku"
                              maxLength={20}
                              value={this.state.prod.sku}
                              placeholder=""
                              autoComplete="off"
                              onChange={(e) => this.handleInputChange(e)}
                            />
                          </Col>
                        </Form.Group>
                        <div className="p-3 border">
                          <Form.Group as={Row} className="mb-1" controlId="">
                            <Form.Label column sm="4">
                              MRP / BasePrice (*)
                            </Form.Label>
                            <Col sm="8">
                              <Form.Control
                                type="number"
                                step="0.01"
                                name="mrp"
                                min={0}
                                // size="lg"
                                value={this.state.prod.mrp}
                                required
                                className="w-50"
                                style={{ fontSize: "24px" }}
                                placeholder=""
                                autoComplete="off"
                                onChange={(e) => this.handleInputChange(e)}
                              />
                            </Col>
                          </Form.Group>
                          {/* <Form.Group as={Row} className="mb-1" controlId="">
                            <Form.Label column sm="4">
                              T.Away Charges(%) (*)
                            </Form.Label>
                            <Col sm="8">
                              <Form.Control
                                type="number"
                                name="takeAwayChargeInPercentage"
                                value={
                                  this.state.prod.takeAwayChargeInPercentage
                                }
                                required
                                min={0}
                                className=""
                                placeholder=""
                                autoComplete="off"
                                onChange={(e) => this.handleInputChange(e)}
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row} className="mb-1" controlId="">
                            <Form.Label column sm="4">
                              H.Del Charges(%) (*)
                            </Form.Label>
                            <Col sm="8">
                              <Form.Control
                                type="number"
                                name="homeDeliveryChargeInPercentage"
                                value={
                                  this.state.prod.homeDeliveryChargeInPercentage
                                }
                                required
                                min={0}
                                className=""
                                placeholder=""
                                autoComplete="off"
                                onChange={(e) => this.handleInputChange(e)}
                              />
                            </Col>
                          </Form.Group> */}
                          <Form.Group as={Row} className="mb-1" controlId="">
                            <Form.Label column sm="4">
                              Discount(%) (*)
                            </Form.Label>
                            <Col sm="8">
                              <Form.Control
                                type="number"
                                name="discount"
                                // size="lg"
                                className="w-50"
                                style={{ fontSize: "24px" }}
                                min={0}
                                value={this.state.prod.discount}
                                required
                                placeholder=""
                                autoComplete="off"
                                onChange={(e) => this.handleInputChange(e)}
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row} className="mb-1" controlId="">
                            <Form.Label column sm="4">
                              Selling Price
                            </Form.Label>
                            <Col sm="8">
                              <h2 className="fw-bold text-dark">
                                {/* BASE: */}
                                {Number(
                                  this.context.GetBasePrice(this.state.prod)
                                ).toLocaleString(
                                  this.context.storeSettings == ""
                                    ? "en-IN"
                                    : this.context.storeSettings.defaultLocale,
                                  {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 1,
                                    style: "currency",
                                    currency:
                                      this.context.storeSettings == ""
                                        ? "INR"
                                        : this.context.storeSettings
                                            .defaultCurrency,
                                  }
                                )}
                                {/* <br />
                                TA:{" "}
                                {Number(
                                  this.context.GetTakeAwayPrice(this.state.prod)
                                ).toLocaleString(
                                  this.context.storeSettings == ""
                                    ? "en-IN"
                                    : this.context.storeSettings.defaultLocale,
                                  {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 1,
                                    style: "currency",
                                    currency:
                                      this.context.storeSettings == ""
                                        ? "INR"
                                        : this.context.storeSettings
                                            .defaultCurrency,
                                  }
                                )}
                                <br />
                                HD:{" "}
                                {Number(
                                  this.context.GetHomeDeliveryPrice(
                                    this.state.prod
                                  )
                                ).toLocaleString(
                                  this.context.storeSettings == ""
                                    ? "en-IN"
                                    : this.context.storeSettings.defaultLocale,
                                  {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 1,
                                    style: "currency",
                                    currency:
                                      this.context.storeSettings == ""
                                        ? "INR"
                                        : this.context.storeSettings
                                            .defaultCurrency,
                                  }
                                )} */}
                              </h2>
                              {/* <Form.Control
                                type="number"
                                min={0}
                                name="sellingPrice"
                                value={Math.round(
                                  Number(this.state.sellingPrice),
                                  0
                                )}
                                required
                                disabled
                                placeholder=""
                                autoComplete="off"
                              /> */}
                            </Col>
                          </Form.Group>
                        </div>
                        <Form.Group as={Row} className="mb-1" controlId="">
                          <Form.Label column sm="4">
                            In-Stock Qty(*)
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              type="number"
                              min={0}
                              name="inStockQty"
                              className="w-50"
                              style={{ fontSize: "24px" }}
                              value={this.state.prod.inStockQty}
                              required
                              placeholder=""
                              autoComplete="off"
                              onChange={(e) => this.handleInputChange(e)}
                            />
                          </Col>
                        </Form.Group>
                      </>
                    ) : (
                      <>
                        <div className="border bg-white p-2 text-primary">
                          <Form.Group as={Row} className="mb-1 " controlId="">
                            <Form.Label column sm="4">
                              Cusom Field 1
                            </Form.Label>
                            <Col sm="8">
                              <Form.Select
                                name="customField1"
                                value={this.state.prod.customField1}
                                autoComplete="off"
                                onChange={(e) => this.handleInputChange(e)}
                              >
                                <option value={""}></option>
                                {this.state.ProductDataType.map((m) => (
                                  <>
                                    <option value={m.name}>{m.name}</option>
                                  </>
                                ))}

                                {/* <option value={"Size"}>Size</option>
                              <option value={"Custom1"}>Size</option> */}
                              </Form.Select>
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row} className="mb-1 " controlId="">
                            <Form.Label column sm="4">
                              Cusom Field 2
                            </Form.Label>
                            <Col sm="8">
                              <Form.Select
                                name="customField2"
                                value={this.state.prod.customField2}
                                autoComplete="off"
                                onChange={(e) => this.handleInputChange(e)}
                              >
                                <option value={""}></option>
                                {this.state.ProductDataType.map((m) => (
                                  <>
                                    <option value={m.name}>{m.name}</option>
                                  </>
                                ))}
                              </Form.Select>
                            </Col>
                          </Form.Group>
                          {/* <Form.Group as={Row} className="mb-1 " controlId="">
                          <Form.Label column sm="4">
                            Cusom Field 3
                          </Form.Label>
                          <Col sm="8">
                            <Form.Select
                              name="customField3"
                              value={this.state.prod.customField3}
                              autoComplete="off"
                              onChange={(e) => this.handleInputChange(e)}
                            >
                              <option value={""}></option>
                              {this.state.ProductDataType.map((m) => (
                                <>
                                  <option value={m.name}>{m.name}</option>
                                </>
                              ))}
                            </Form.Select>
                          </Col>
                        </Form.Group> */}
                        </div>
                      </>
                    )}

                    <Form.Group as={Row} className="mb-1" controlId="">
                      <Form.Label column sm="4">
                        Weight Variant
                      </Form.Label>
                      <Col sm="8">
                        <Switch
                          onChange={this.handleWeightVariantChange}
                          checked={this.state.prod.weightVariant}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md={4} className="">
                    <Form.Group as={Row} className="mb-1" controlId="">
                      <Col sm="12">
                        Related Products - SKU List in CSV Format ex:
                        5002,3006,4545
                        <Form.Control
                          as="textarea"
                          type="text"
                          rows={2}
                          maxLength={254}
                          name="relatedProductsSkuListByCsv"
                          value={this.state.prod.relatedProductsSkuListByCsv}
                          placeholder=""
                          autoComplete="off"
                          onChange={(e) => this.handleInputChange(e)}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-1" controlId="">
                      <Col sm="12">
                        Main Description-(upto 5000 characters) (HTML tags
                        supported):&nbsp;&nbsp;
                        <a href="https://www.geeksforgeeks.org/most-commonly-used-tags-in-html">
                          Basic HTML tags Reference
                        </a>
                        <Form.Control
                          as="textarea"
                          type="text"
                          rows={6}
                          maxLength={4999}
                          name="description"
                          value={this.state.prod.description}
                          placeholder=""
                          autoComplete="off"
                          onChange={(e) => this.handleInputChange(e)}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-1" controlId="">
                      <Col sm="12">
                        Detailed Description HTML File (HTML tags
                        supported):&nbsp;&nbsp;
                        <Form.Select
                          type="text"
                          name="description2FileName"
                          value={this.state.prod.description2FileName}
                          autoComplete="off"
                          className="text-start"
                          onChange={(e) => this.handleInputChange(e)}
                        >
                          <option value=""></option>
                          {this.context.images
                            .filter((f) => f.name.includes(".html"))
                            .map((r) => (
                              <option value={r.name}>{r.name}</option>
                            ))}
                        </Form.Select>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-1" controlId="">
                      <OverlayTrigger
                        overlay={
                          <Tooltip>
                            Note: when this is ON, delivery cost of this product
                            will not be considered in Total Order cost.
                          </Tooltip>
                        }
                      >
                        <Form.Label column sm="8">
                          Delivery Price Included?
                          <b className="text-primary">&nbsp;&nbsp;Help!</b>
                        </Form.Label>
                      </OverlayTrigger>
                      <Col sm="4">
                        <Switch
                          onChange={this.handleDeliveryPriceIncluded}
                          checked={this.state.prod.deliveryCostIncluded}
                        />
                        <br />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <br />
                {this.state.prod.productVariantType == 2 ? (
                  <>
                    <div className="bg-light border  p-2 m-2">
                      <Row className="m-0">
                        <Col md={8} className="text-start text-dark my-3">
                          <span>
                            <h4>Product Variants</h4>
                            <h6></h6>
                          </span>
                        </Col>
                        <Col md={4} className="text-end align-bottom">
                          <Button
                            variant="primary"
                            classname="px-2"
                            onClick={(e) =>
                              this.handleAddProductMultiVariants(e)
                            }
                          >
                            + Add
                          </Button>
                          &nbsp;&nbsp;
                        </Col>
                      </Row>
                      <Row className="m-0">
                        <Col md={12}>
                          <Table
                            bordered
                            size="sm"
                            className=" text-start"
                            style={{ tableLayout: "fixed" }}
                          >
                            <thead className="bg-secondary text-white">
                              <tr size="lg" className="text-center">
                                {this.state.prod.customField1 != "" &&
                                this.state.prod.customField1 != undefined ? (
                                  <>
                                    <td>{this.state.prod.customField1}</td>
                                  </>
                                ) : (
                                  <></>
                                )}{" "}
                                {this.state.prod.customField2 != "" &&
                                this.state.prod.customField2 != undefined ? (
                                  <>
                                    <td>{this.state.prod.customField2}</td>
                                  </>
                                ) : (
                                  <></>
                                )}{" "}
                                {this.state.prod.customField3 != "" &&
                                this.state.prod.customField3 != undefined ? (
                                  <>
                                    <td>{this.state.prod.customField3}</td>
                                  </>
                                ) : (
                                  <></>
                                )}{" "}
                                <td>SKU</td>
                                <td>Seq.No</td>
                                {/* <td>SKU</td> */}
                                <td>MRP</td>
                                <td>Discount %</td>
                                <td>S.Price</td>
                                <td>InStock</td>
                              </tr>
                            </thead>
                            <tbody size="lg">
                              {this.state.productMultiVariants != undefined ? (
                                this.state.productMultiVariants
                                  // .sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1))
                                  .map((row, i) => (
                                    <>
                                      <tr size="lg">
                                        {this.state.prod.customField1 != "" &&
                                        this.state.prod.customField1 !=
                                          undefined ? (
                                          <>
                                            <td>
                                              <Form.Select
                                                type="text"
                                                name="customValue1"
                                                value={row.customValue1}
                                                required
                                                autoComplete="off"
                                                onChange={(e) =>
                                                  this.handleProductMultivariantChange(
                                                    e,
                                                    i
                                                  )
                                                }
                                              >
                                                <option value={""}></option>
                                                {this.state.ProductDataTypeValues.filter(
                                                  (f) =>
                                                    f.dataTypeName ==
                                                    this.state.prod.customField1
                                                ).map((values) => (
                                                  <option value={values.value}>
                                                    {values.value}
                                                  </option>
                                                ))}
                                              </Form.Select>
                                            </td>
                                          </>
                                        ) : (
                                          <></>
                                        )}{" "}
                                        {this.state.prod.customField2 != "" &&
                                        this.state.prod.customField2 !=
                                          undefined ? (
                                          <>
                                            <td>
                                              <Form.Select
                                                type="text"
                                                name="customValue2"
                                                required
                                                value={row.customValue2}
                                                autoComplete="off"
                                                onChange={(e) =>
                                                  this.handleProductMultivariantChange(
                                                    e,
                                                    i
                                                  )
                                                }
                                              >
                                                <option value={""}></option>
                                                {this.state.ProductDataTypeValues.filter(
                                                  (f) =>
                                                    f.dataTypeName ==
                                                    this.state.prod.customField2
                                                ).map((values) => (
                                                  <option value={values.value}>
                                                    {values.value}
                                                  </option>
                                                ))}
                                              </Form.Select>
                                            </td>
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                        <td>
                                          <Form.Control
                                            type="text"
                                            name="sku"
                                            value={row.sku}
                                            className="text-center"
                                            placeholder=""
                                            autoComplete="off"
                                            onChange={(e) =>
                                              this.handleProductMultivariantChange(
                                                e,
                                                i
                                              )
                                            }
                                          />
                                        </td>
                                        <td>
                                          <Form.Control
                                            type="number"
                                            min={1}
                                            name="seqNo"
                                            value={row.seqNo}
                                            required
                                            className="text-center"
                                            placeholder=""
                                            autoComplete="off"
                                            onChange={(e) =>
                                              this.handleProductMultivariantChange(
                                                e,
                                                i
                                              )
                                            }
                                          />
                                        </td>
                                        {/* <td>
                                          <Form.Control
                                        type="number"
                                        min={1}
                                        name="sku"
                                        value={row.sku}
                                        required
                                        disabled
                                        className="text-center"
                                        placeholder=""
                                        autoComplete="off"
                                        onChange={(e) =>
                                          this.handleProductMultivariantChange(
                                            e,
                                            i
                                          )
                                        }
                                      />
                                        </td> */}
                                        <td>
                                          <Form.Control
                                            type="number"
                                            name="mrp"
                                            value={row.mrp}
                                            required
                                            className="text-center"
                                            placeholder=""
                                            autoComplete="off"
                                            onChange={(e) =>
                                              this.handleProductMultivariantChange(
                                                e,
                                                i
                                              )
                                            }
                                          />
                                        </td>
                                        <td>
                                          <Form.Control
                                            type="number"
                                            name="discount"
                                            min={0}
                                            value={row.discount}
                                            className="text-center"
                                            placeholder=""
                                            autoComplete="off"
                                            onChange={(e) =>
                                              this.handleProductMultivariantChange(
                                                e,
                                                i
                                              )
                                            }
                                          />
                                        </td>
                                        <td className="text-center">
                                          <h3>
                                            {Number(
                                              row.mrp -
                                                (row.mrp * row.discount) / 100
                                            ).toLocaleString(
                                              this.context.storeSettings == ""
                                                ? "en-IN"
                                                : this.context.storeSettings
                                                    .defaultLocale,
                                              {
                                                minimumFractionDigits: 0,
                                                maximumFractionDigits: 0,
                                                style: "currency",
                                                currency:
                                                  this.context.storeSettings ==
                                                  ""
                                                    ? "INR"
                                                    : this.context.storeSettings
                                                        .defaultCurrency,
                                              }
                                            )}
                                          </h3>
                                        </td>
                                        <td>
                                          <Form.Control
                                            type="number"
                                            name="inStockQty"
                                            min={0}
                                            value={row.inStockQty}
                                            className="text-center"
                                            placeholder=""
                                            autoComplete="off"
                                            onChange={(e) =>
                                              this.handleProductMultivariantChange(
                                                e,
                                                i
                                              )
                                            }
                                          />
                                        </td>
                                        <td className="text-center">
                                          <OverlayTrigger
                                            overlay={<Tooltip>Delete!</Tooltip>}
                                          >
                                            <Button
                                              variant="outline-danger"
                                              onClick={(e) => {
                                                this.handleRemoveProductMultiVariantClick(
                                                  i
                                                );
                                              }}
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                class="bi bi-trash"
                                                viewBox="0 0 16 16"
                                              >
                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                <path
                                                  fill-rule="evenodd"
                                                  d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                                />
                                              </svg>
                                            </Button>
                                          </OverlayTrigger>
                                        </td>
                                      </tr>
                                    </>
                                  ))
                              ) : (
                                <></>
                              )}
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <br />
                {window.innerWidth > 1200 ? (
                  <>
                    <div className="bg-light border  p-2 m-2">
                      <Row className="m-0">
                        <Col md={8} className="text-start text-dark my-3">
                          <span>
                            <h4>Product Images</h4>
                            <h6></h6>
                          </span>
                        </Col>
                        <Col md={4} className="text-end align-bottom">
                          <Button
                            variant="primary"
                            classname="px-2"
                            onClick={(e) => this.handleAddImages(e)}
                          >
                            + Add
                          </Button>
                          &nbsp;&nbsp;
                        </Col>
                      </Row>
                      <Row className="m-0 p-0 bg-light border">
                        <Col>
                          <Table bordered size="sm" className=" text-start">
                            <thead className="bg-secondary text-white">
                              <tr size="lg">
                                <td width="40%">Image/Video FileName</td>
                                <td width="10%" className="text-center">
                                  Seq No
                                </td>
                                <td width="10%" className="text-center">
                                  Image
                                </td>
                                <td width="30%" className="text-center">
                                  Poster File(if Video)
                                </td>
                                <td width="10%" className="text-center">
                                  Options
                                </td>
                              </tr>
                            </thead>
                            <tbody size="lg">
                              {this.state.productImages != undefined
                                ? this.state.productImages
                                    .sort((a, b) =>
                                      a.seqNo > b.seqNo ? 1 : -1
                                    )
                                    .map((row, i) => (
                                      <>
                                        <tr
                                          height="30pt"
                                          className="align-middle "
                                        >
                                          <td>
                                            <Select
                                              value={{
                                                value: row.fileName,
                                                label: row.fileName,
                                              }}
                                              onChange={(e) =>
                                                this.handleImageSelectionChange(
                                                  e,
                                                  i
                                                )
                                              }
                                              options={this.state.images}
                                            ></Select>
                                          </td>
                                          <td>
                                            <Form.Control
                                              type="number"
                                              min={1}
                                              name="seqNo"
                                              value={row.seqNo}
                                              required
                                              className="text-center"
                                              placeholder=""
                                              autoComplete="off"
                                              onChange={(e) =>
                                                this.handleImageseqNoChange(
                                                  e,
                                                  i
                                                )
                                              }
                                            />
                                          </td>
                                          <td className="text-center">
                                            <img
                                              width="50pt"
                                              height="50pt"
                                              src={
                                                this.context.store
                                                  .storageBlobUrl +
                                                this.context.store
                                                  .storageBlobContainerName +
                                                "/images/" +
                                                row.fileName +
                                                this.context.store
                                                  .storageSasToken
                                              }
                                            />
                                          </td>
                                          <td>
                                            <Select
                                              value={{
                                                value: row.posterName,
                                                label: row.posterName,
                                              }}
                                              onChange={(e) =>
                                                this.handlePosterSelectionChange(
                                                  e,
                                                  i
                                                )
                                              }
                                              options={this.state.images}
                                            ></Select>
                                          </td>
                                          <td className="text-center">
                                            <OverlayTrigger
                                              overlay={
                                                <Tooltip>Delete!</Tooltip>
                                              }
                                            >
                                              <Button
                                                variant="outline-danger"
                                                onClick={(e) => {
                                                  this.handleRemoveClick(i);
                                                }}
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="16"
                                                  height="16"
                                                  fill="currentColor"
                                                  class="bi bi-trash"
                                                  viewBox="0 0 16 16"
                                                >
                                                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                  <path
                                                    fill-rule="evenodd"
                                                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                                  />
                                                </svg>
                                              </Button>
                                            </OverlayTrigger>
                                          </td>
                                        </tr>
                                      </>
                                    ))
                                : ""}
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                      {window.location.hostname ==
                      "green-admin.elasticcart.com" ? (
                        <>
                          <Row className="m-0">
                            <Col md={4} className="text-start text-dark my-3">
                              <span>
                                <h4>Right Image</h4>
                              </span>
                            </Col>
                            <Col md={8} className="text-start align-bottom">
                              <Select
                                value={{
                                  value: this.state.prod.image2,
                                  label: this.state.prod.image2,
                                }}
                                onChange={(e) => this.Image2Change(e)}
                                options={this.state.images}
                              ></Select>
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </Modal.Body>

            <Modal.Footer className="bg-light">
              <Form.Group>
                <Button variant="primary" size="lg" type="submit">
                  Save
                </Button>
              </Form.Group>
              {/* <Form.Group>
                <Button variant="primary" size="lg" type="" disabled="true">
                  Preview...
                </Button>
              </Form.Group> */}
              <Button variant="danger" size="lg" onClick={this.props.onHide}>
                Close
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }
}
