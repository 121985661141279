import React, { Component } from "react";
import CartContext from "./CartContext";
import { MessageBox } from "./MessageBox";
import { LoadSpinner } from "./LoadSpinner";
import Select from "react-select";
import reactSelect from "react-select";

import {
  FloatingLabel,
  OverlayTrigger,
  Tooltip,
  Table,
  InputGroup,
  Modal,
  Button,
  Row,
  Col,
  Form,
  Image,
  Alert,
} from "react-bootstrap";

// https://www.geeksforgeeks.org/how-to-create-an-editable-table-with-add-delete-and-search-filter-using-reactjs/
export class ThemeAddOrEditModal extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);

    this.state = {
      theme: {
        themeName: "",
        checkTermsAndConditionsOnCheckOut: false,
      },
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      loadSpinnerOpen: false,
      selectedProductTheme: "",
    };
  }

  ApplyProductTheme = () => {
    var id = "";

    var pTheme = this.context.productThemes.filter(
      (f) => f.themeName == this.state.selectedProductTheme
    );

    if (pTheme.length == 1) {
      if (this.props.Type === "Edit") {
        var tTheme = JSON.parse(JSON.stringify(pTheme[0]));
        tTheme.id = this.state.theme.id;
        tTheme.themeName = this.state.theme.themeName;
        tTheme.themeDescription = this.state.theme.themeDescription;
        tTheme.storeId = this.context.storeId;
        this.setState({ theme: tTheme });
      } else {
        var tTheme = JSON.parse(JSON.stringify(pTheme[0]));
        tTheme.id = "00000000-0000-0000-0000-000000000000";
        tTheme.themeName = "";
        tTheme.themeDescription = "";
        tTheme.storeId = this.context.storeId;
        this.setState({ theme: tTheme });
      }
    }

    this.setState({ selectedProductTheme: "" });
  };

  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
    this.props.onHide();
  };
  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };
  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };

  AddOrUpdateThemeInDB = (action) => {
    this.ShowLoadSpinner();

    var tmpTheme = {};
    if (this.props.Type == "Edit") {
      tmpTheme = this.state.theme;
    } else {
      this.state.theme.storeId = this.context.storeId;
      tmpTheme = this.state.theme;
    }

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(tmpTheme),
    };

    if (this.props.ThemeType == "StoreTheme") {
      fetch(
        process.env.REACT_APP_API + "StoreManager/AddOrUpdateTheme/",
        requestOptions
      )
        .then((res) => {
          if (res.status == 200) {
            this.context.getThemes();
            this.HideLoadSpinner();
            this.ShowMessagBox(
              "Status",
              "Saved Successfully. Click Refresh Button to see the changes!"
            );
            return false;
          }
          this.HideLoadSpinner();
          alert("Not Saved. Theme Name must be unique. try again!");
          return;
        })
        .catch((error) => {
          this.HideLoadSpinner();
          alert(
            "Exception. Received an Exception. Try after sometime !" + error
          );
        });
    } else if (this.props.ThemeType == "ProductTheme") {
      fetch(
        process.env.REACT_APP_API + "StoreManager/AddOrUpdateProductTheme/",
        requestOptions
      )
        .then((res) => {
          if (res.status == 200) {
            this.context.getProductThemes();
            this.HideLoadSpinner();
            this.ShowMessagBox(
              "Status",
              "Saved Successfully. Click Refresh Button to see the changes!"
            );
            return false;
          }
          this.HideLoadSpinner();
          alert("Not Saved. Theme Name must be unique. try again!");
          return;
        })
        .catch((error) => {
          this.HideLoadSpinner();
          alert(
            "Exception. Received an Exception. Try after sometime !" + error
          );
        });
    }
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;

    //validate for minus values not to allow
    switch (name.trim()) {
      case "selectedProductTheme":
        this.setState({ selectedProductTheme: value });
        return;
      case "themeName":
        this.state.theme.themeName = value;
        break;
      case "themeDescription":
        this.state.theme.themeDescription = value;
        break;
      //Common
      case "hideWhatsAppIcon":
        this.state.theme.hideWhatsAppIcon = value;
        break;
      case "hideHomeIcon":
        this.state.theme.hideHomeIcon = value;
        break;
      case "cartIcon":
        this.state.theme.cartIcon = value;
        break;
      case "homepageBackgroundColor":
        this.state.theme.homepageBackgroundColor = value;
        break;
      case "productGroupBackgroundColor":
        this.state.theme.productGroupBackgroundColor = value;
        break;
      case "homepageTitlesAlign":
        this.state.theme.homepageTitlesAlign = value;
        break;
      case "homepageTitlesAlign":
        this.state.theme.homepageTitlesAlign = value;
        break;
      case "homepageTitlesTextColor":
        this.state.theme.homepageTitlesTextColor = value;
        break;
      case "homepageTitlesTextCase":
        this.state.theme.homepageTitlesTextCase = value;
        break;
      case "sliderArrowBackgroundColor":
        this.state.theme.sliderArrowBackgroundColor = value;
        break;
      case "hideProductCount":
        this.state.theme.hideProductCount = value;
        break;
      case "showAllProductsByMainCategoryOnHomePage":
        this.state.theme.showAllProductsByMainCategoryOnHomePage = value;
        break;
      case "mainNavBarBackgroundColor":
        this.state.theme.mainNavBarBackgroundColor = value;
        break;
      case "mainNavBarTextColor":
        this.state.theme.mainNavBarTextColor = value;
        break;
      case "badgeBackgroundColor":
        this.state.theme.badgeBackgroundColor = value;
        break;
      case "badgeBackgroundColor":
        this.state.theme.badgeBackgroundColor = value;
        break;
      case "badgeTextColor":
        this.state.theme.badgeTextColor = value;
        break;
      case "allProductToGetQuote":
        this.state.theme.allProductToGetQuote = value;
        break;
      case "showProductSingleViewInNewpage":
        this.state.theme.showProductSingleViewInNewpage = value;
        break;

      case "categoryTextColor":
        this.state.theme.categoryTextColor = value;
        break;
      case "mainCategoryDisplayShape":
        this.state.theme.mainCategoryDisplayShape = value;
        break;

      case "subCategoryAndBrandDisplayShape":
        this.state.theme.subCategoryAndBrandDisplayShape = value;
        break;

      case "productCardBackgroundColor":
        this.state.theme.productCardBackgroundColor = value;
        break;
      case "productCardTextColor":
        this.state.theme.productCardTextColor = value;
        break;
      case "productCardBorderColor":
        this.state.theme.productCardBorderColor = value;
        break;
      case "productNameHeightOnProductCard":
        this.state.theme.productNameHeightOnProductCard = value;
        break;
      case "productNameAlignOnProductCard":
        this.state.theme.productNameAlignOnProductCard = value;
        break;

      //Desktop
      case "hideShopByCategoryMenu":
        this.state.theme.hideShopByCategoryMenu = value;
        break;

      case "showTopStripOnMainNavBar":
        this.state.theme.showTopStripOnMainNavBar = value;
        break;
      case "showGetQuoteOnMenu":
        this.state.theme.showGetQuoteOnMenu = value;
        break;
      case "showAboutUsOnMenu":
        this.state.theme.showAboutUsOnMenu = value;
        break;
      case "showPayCheckoutOnMenu":
        this.state.theme.showPayCheckoutOnMenu = value;
        break;

      case "homeMarginSpace":
        this.state.theme.homeMarginSpace = value;
        break;
      case "homePaddingSpace":
        this.state.theme.homePaddingSpace = value;
        break;
      case "homepageTitlesFontSize":
        this.state.theme.homepageTitlesFontSize = value;
        break;
      case "menuFontSize":
        this.state.theme.menuFontSize = value;
        break;
      case "menuNavBarbackgroundColor":
        this.state.theme.menuNavBarbackgroundColor = value;
        break;
      case "menuTextColor":
        this.state.theme.menuTextColor = value;
        break;
      case "maincategoryImageWidth":
        this.state.theme.maincategoryImageWidth = value;
        break;
      case "subcategoryNameHeight":
        this.state.theme.subcategoryNameHeight = value;
        break;
      case "maincategoryNameHeight":
        this.state.theme.maincategoryNameHeight = value;
        break;
      case "productCardImagewidth":
        this.state.theme.productCardImagewidth = value;
        break;
      case "filtersTextColor":
        this.state.theme.filtersTextColor = value;
        break;
      case "hideFilters":
        this.state.theme.hideFilters = e.target.checked;
        break;
      case "checkTermsAndConditionsOnCheckOut":
        this.state.theme.checkTermsAndConditionsOnCheckOut = e.target.checked;
        break;
      case "invertMenuIconOnMobile":
        this.state.theme.invertMenuIconOnMobile = value;
        break;
      case "priceFontSizeOnProductCard":
        this.state.theme.priceFontSizeOnProductCard = value;
        break;
      case "showPriceOnCardswhenHover":
        this.state.theme.showPriceOnCardswhenHover = value;
        break;
      case "slidesToShowInProductList":
        this.state.theme.slidesToShowInProductList = value;
        break;

      //--Mobile
      case "maincategoryImageWidthMobile":
        this.state.theme.maincategoryImageWidthMobile = value;
        break;
      case "homepageTitlesFontSizeMobile":
        this.state.theme.homepageTitlesFontSizeMobile = value;
        break;
      case "priceFontSizeOnProductCardMobile":
        this.state.theme.priceFontSizeOnProductCardMobile = value;
        break;

      //  HOMEPage(Desktop)
      case "mainCategoryBackgroundColor":
        this.state.theme.mainCategoryBackgroundColor = value;
        break;
      case "mainCategoryTextColor":
        this.state.theme.mainCategoryTextColor = value;
        break;
      case "mainCategoryTotalRows":
        this.state.theme.mainCategoryTotalRows = value;
        break;
      case "subCategoryBackgroundColor":
        this.state.theme.subCategoryBackgroundColor = value;
        break;
      case "subCategoryTextColor":
        this.state.theme.subCategoryTextColor = value;
        break;
      case "subCategoryTotalRows":
        this.state.theme.subCategoryTotalRows = value;
        break;

      case "mainCategoryName":
        this.state.theme.mainCategoryName = value;
        break;
      case "subCategoryName":
        this.state.theme.subCategoryName = value;
        break;
      case "hideMainCategory":
        this.state.theme.hideMainCategory = value;
        break;
      case "hideSubCategory":
        this.state.theme.hideSubCategory = value;
        break;
      case "hideCarousel":
        this.state.theme.hideCarousel = value;
        break;
      case "hideMenu":
        this.state.theme.hideMenu = value;
        break;
      case "carouselImageHeight":
        this.state.theme.carouselImageHeight = value;
        break;
      case "storeLogoImageWidth":
        this.state.theme.storeLogoImageWidth = value;
        break;
      case "invertLogo":
        this.state.theme.invertLogo = value;
        break;
      case "mainCategorySlideWidth":
        this.state.theme.mainCategorySlideWidth = value;
        break;
      case "subCategorySlidesWidth":
        this.state.theme.subCategorySlidesWidth = value;
        break;
      case "productGropupSlidesWidth":
        this.state.theme.productGropupSlidesWidth = value;
        break;
      case "mainCategoryCardWidth":
        this.state.theme.mainCategoryCardWidth = value;
        break;
      case "mainCategoryImageHeight":
        this.state.theme.mainCategoryImageHeight = value;
        break;
      case "subCategoryCardWidth":
        this.state.theme.subCategoryCardWidth = value;
        break;
      case "subCategoryImageHeight":
        this.state.theme.subCategoryImageHeight = value;
        break;

      //  HOMEPage(Mobile)
      case "mainCategoryNameMobile":
        this.state.theme.mainCategoryNameMobile = value;
        break;
      case "subCategoryNameMobile":
        this.state.theme.subCategoryNameMobile = value;
        break;
      case "hideMainCategoryMobile":
        this.state.theme.hideMainCategoryMobile = value;
        break;
      case "showAllMainCategoryOnHomePageMobile":
        this.state.theme.showAllMainCategoryOnHomePageMobile = value;
        break;
      case "maincategoryNameHeightMobile":
        this.state.theme.maincategoryNameHeightMobile = value;
        break;
      case "subcategoryNameHeightMobile":
        this.state.theme.subcategoryNameHeightMobile = value;
        break;
      case "hideSubCategoryMobile":
        this.state.theme.hideSubCategoryMobile = value;
        break;
      case "hideCarouselMobile":
        this.state.theme.hideCarouselMobile = value;
        break;
      case "hideMenuMobile":
        this.state.theme.hideMenuMobile = value;
        break;
      case "carouselImageHeightMobile":
        this.state.theme.carouselImageHeightMobile = value;
        break;
      case "storeLogoImageWidthMobile":
        this.state.theme.storeLogoImageWidthMobile = value;
        break;
      case "mainCategorySlideWidthMobile":
        this.state.theme.mainCategorySlideWidthMobile = value;
        break;
      case "subCategorySlideWidthMobile":
        this.state.theme.subCategorySlideWidthMobile = value;
        break;
      case "productGropupSlideWidthMobile":
        this.state.theme.productGropupSlideWidthMobile = value;
        break;
      case "mainCategoryCardWidthMobile":
        this.state.theme.mainCategoryCardWidthMobile = value;
        break;
      case "mainCategoryImageHeightMobile":
        this.state.theme.mainCategoryImageHeightMobile = value;
        break;
      case "subCategoryCardWidthMobile":
        this.state.theme.subCategoryCardWidthMobile = value;
        break;
      case "subCategoryImageHeightMobile":
        this.state.theme.subCategoryImageHeightMobile = value;
        break;

      // PRODUCT CARD - Desktop
      case "productCardWidth":
        this.state.theme.productCardWidth = value;
        break;
      case "productCardImageHeight":
        this.state.theme.productCardImageHeight = value;
        break;
      case "noOfCardsOnProductGroupToShow":
        this.state.theme.noOfCardsOnProductGroupToShow = value;
        break;
      case "noOfCardsOnProductListToShow":
        this.state.theme.noOfCardsOnProductListToShow = value;
        break;

      // PRODUCT CARD - Mobile
      case "productCardWidthMobile":
        this.state.theme.productCardWidthMobile = value;
        break;
      case "productCardImageHeightMobile":
        this.state.theme.productCardImageHeightMobile = value;
        break;

      // ProductSingleView- DESKTOP
      case "productSingleViewProductCardWidth":
        this.state.theme.productSingleViewProductCardWidth = value;
        break;
      case "productSingleViewProductCardImageHeight":
        this.state.theme.productSingleViewProductCardImageHeight = value;
        break;

      // ProductSingleView- Mobile
      case "productSingleViewProductCardWidthMobile":
        this.state.theme.productSingleViewProductCardWidthMobile = value;
        break;
      case "productSingleViewProductCardImageHeightMobile":
        this.state.theme.productSingleViewProductCardImageHeightMobile = value;
        break;
    }
    this.setState({ theme: this.state.theme }, () => {});
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.AddOrUpdateThemeInDB();
  };

  Apply = (event) => {
    this.AddOrUpdateThemeInDB();
    event.preventDefault();
    event.stopPropagation();
  };

  componentDidMount() {
    if (this.props.Type == "Edit") {
      this.setState({ theme: this.props.theme });
    }
  }

  render() {
    return (
      <div className="container ">
        <Modal
          {...this.props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
          dialogClassName="ThemeModal"
        >
          {this.state.msgboxOpen === true ? (
            <MessageBox
              open={this.state.msgboxOpen}
              title={this.state.msgboxTitle}
              message={this.state.msgboxMessage}
              callback={this.CloseMessagBoxCallback}
            />
          ) : (
            ""
          )}
          {this.state.loadSpinnerOpen === true ? (
            <LoadSpinner open="true"></LoadSpinner>
          ) : (
            ""
          )}
          <Form onSubmit={this.handleSubmit}>
            <Modal.Header closeButton className="bg-warning">
              <Modal.Title id="contained-modal-title-vcenter">
                {this.props.Type == "Edit" ? "Edit theme" : "Add theme"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid ">
              <Row className="m-2 p-2 bg-light " style={{ fontSize: "9pt" }}>
                <Col md={6}>
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Form.Label column sm="3">
                      Theme Name
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        name="themeName"
                        value={this.state.theme.themeName}
                        required
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Form.Label column sm="3">
                      Theme Description
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        as="textarea"
                        type="textarea"
                        rows={2}
                        name="themeDescription"
                        value={this.state.theme.themeDescription}
                        required
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col md={2}></Col>
                <Col md={4} className="border">
                  <Form.Group>
                    <Form.Label column sm="12">
                      <b>Product Themes</b> (Caution! To copy a Product-Theme
                      setting below, select a Product-Theme below and Click
                      Apply. This will overwrite below configuration!)
                    </Form.Label>
                    <Form.Select
                      type="text"
                      name="selectedProductTheme"
                      value={this.state.selectedProductTheme}
                      placeholder=""
                      onChange={(e) => this.handleInputChange(e)}
                    >
                      <option value=""></option>
                      {this.context.productThemes.map((m) => (
                        <option value={m.themeName}>{m.themeName}</option>
                      ))}
                    </Form.Select>
                    <div className="text-center">
                      <Button
                        disabled={
                          this.state.selectedProductTheme == "" ? true : false
                        }
                        onClick={(e) => this.ApplyProductTheme()}
                      >
                        Apply{" "}
                      </Button>
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Row
                className="m-2 p-2 bg-light border border-dark"
                style={{ fontSize: "9pt" }}
              >
                <p className="text-center">
                  <h4> Common Settings </h4>
                </p>
                <Col md={6}>
                  <div className="border px-3 ">
                    <p className="text-center">
                      <b>Main Bar</b>
                    </p>
                    <Form.Group as={Row} className="mb-1" controlId="">
                      <Form.Label column sm="8">
                        MainBar Background Color
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          type="text"
                          name="mainNavBarBackgroundColor"
                          value={this.state.theme.mainNavBarBackgroundColor}
                          required
                          placeholder=""
                          onChange={(e) => this.handleInputChange(e)}
                        ></Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-1" controlId="">
                      <Form.Label column sm="8">
                        MainBar TextColor
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          type="text"
                          name="mainNavBarTextColor"
                          value={this.state.theme.mainNavBarTextColor}
                          required
                          placeholder=""
                          onChange={(e) => this.handleInputChange(e)}
                        ></Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-1 " controlId="">
                      <Form.Label column sm="8">
                        Invert Logo Color (in %)
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          type="text"
                          min={0}
                          name="invertLogo"
                          value={this.state.theme.invertLogo}
                          required
                          placeholder=""
                          autoComplete="off"
                          onChange={(e) => this.handleInputChange(e)}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-1" controlId="">
                      <Form.Label column sm="8">
                        Cart Icon
                      </Form.Label>
                      <Col sm="4">
                        <Form.Select
                          type="text"
                          name="cartIcon"
                          value={this.state.theme.cartIcon}
                          required
                          placeholder=""
                          onChange={(e) => this.handleInputChange(e)}
                        >
                          <option value=""></option>
                          <option value="Cart">Cart</option>
                          <option value="Bag">Bag</option>
                        </Form.Select>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-1" controlId="">
                      <Form.Label column sm="8">
                        Hide Home Icon
                      </Form.Label>
                      <Col sm="4">
                        <Form.Select
                          type="text"
                          name="hideHomeIcon"
                          value={this.state.theme.hideHomeIcon}
                          required
                          placeholder=""
                          onChange={(e) => this.handleInputChange(e)}
                        >
                          <option value=""></option>
                          <option value="false">false</option>
                          <option value="true">true</option>
                        </Form.Select>
                      </Col>
                    </Form.Group>
                  </div>
                  <div className="border px-3 ">
                    <p className="text-center">
                      <b>Home Page</b>
                    </p>
                    <Form.Group as={Row} className="mb-1" controlId="">
                      <Form.Label column sm="8">
                        Hide WhasApp Icon
                      </Form.Label>
                      <Col sm="4">
                        <Form.Select
                          type="text"
                          name="hideWhatsAppIcon"
                          value={this.state.theme.hideWhatsAppIcon}
                          required
                          placeholder=""
                          onChange={(e) => this.handleInputChange(e)}
                        >
                          <option value=""></option>
                          <option value="false">false</option>
                          <option value="true">true</option>
                        </Form.Select>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-1" controlId="">
                      <Form.Label column sm="8">
                        Homepage Background Color
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          type="text"
                          name="homepageBackgroundColor"
                          value={this.state.theme.homepageBackgroundColor}
                          required
                          placeholder=""
                          onChange={(e) => this.handleInputChange(e)}
                        ></Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-1" controlId="">
                      <Form.Label column sm="8">
                        Homepage All Titles TextColor
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          type="text"
                          name="homepageTitlesTextColor"
                          value={this.state.theme.homepageTitlesTextColor}
                          required
                          placeholder=""
                          onChange={(e) => this.handleInputChange(e)}
                        ></Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-1" controlId="">
                      <Form.Label column sm="8">
                        Category Title Text Color
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          type="text"
                          name="categoryTextColor"
                          value={this.state.theme.categoryTextColor}
                          required
                          placeholder=""
                          onChange={(e) => this.handleInputChange(e)}
                        ></Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-1" controlId="">
                      <Form.Label column sm="8">
                        MainCategory image Shape
                      </Form.Label>
                      <Col sm="4">
                        <Form.Select
                          type="text"
                          name="mainCategoryDisplayShape"
                          value={this.state.theme.mainCategoryDisplayShape}
                          required
                          placeholder=""
                          onChange={(e) => this.handleInputChange(e)}
                        >
                          <option value="rounded">rounded</option>
                          <option value="rounded-circle">rounded-circle</option>
                          <option value="CornersRounded">CornersRounded</option>
                          <option value="TopCornerRounded">
                            TopCornerRounded
                          </option>
                          <option value="CornersHalfRounded">
                            CornersHalfRounded
                          </option>
                        </Form.Select>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-1" controlId="">
                      <Form.Label column sm="8">
                        SubCategory & Brand image Shape
                      </Form.Label>
                      <Col sm="4">
                        <Form.Select
                          type="text"
                          name="subCategoryAndBrandDisplayShape"
                          value={
                            this.state.theme.subCategoryAndBrandDisplayShape
                          }
                          required
                          placeholder=""
                          onChange={(e) => this.handleInputChange(e)}
                        >
                          <option value="rounded">rounded</option>
                          <option value="rounded-circle">rounded-circle</option>
                          <option value="CornersRounded">CornersRounded</option>
                          <option value="TopCornerRounded">
                            TopCornerRounded
                          </option>
                          <option value="CornersHalfRounded">
                            CornersHalfRounded
                          </option>
                        </Form.Select>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-1" controlId="">
                      <Form.Label column sm="8">
                        Homepage Titles TextCase
                      </Form.Label>
                      <Col sm="4">
                        <Form.Select
                          type="text"
                          name="homepageTitlesTextCase"
                          value={this.state.theme.homepageTitlesTextCase}
                          required
                          placeholder=""
                          onChange={(e) => this.handleInputChange(e)}
                        >
                          <option value=""></option>
                          <option value="uppercase">uppercase</option>
                          <option value="lowercase">lowercase</option>
                        </Form.Select>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-1" controlId="">
                      <Form.Label column sm="8">
                        Homepage Title Align
                      </Form.Label>
                      <Col sm="4">
                        <Form.Select
                          type="text"
                          name="homepageTitlesAlign"
                          value={this.state.theme.homepageTitlesAlign}
                          required
                          placeholder=""
                          onChange={(e) => this.handleInputChange(e)}
                        >
                          <option value=""></option>
                          <option value="text-start">text-start</option>
                          <option value="text-center">text-center</option>
                          <option value="text-end">text-end</option>
                        </Form.Select>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-1" controlId="">
                      <Form.Label column sm="8">
                        Product Group Background Color
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          type="text"
                          name="productGroupBackgroundColor"
                          value={this.state.theme.productGroupBackgroundColor}
                          required
                          placeholder=""
                          onChange={(e) => this.handleInputChange(e)}
                        ></Form.Control>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-1" controlId="">
                      <Form.Label column sm="8">
                        Slider Arrow Background Color
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          type="text"
                          name="sliderArrowBackgroundColor"
                          value={this.state.theme.sliderArrowBackgroundColor}
                          required
                          placeholder=""
                          onChange={(e) => this.handleInputChange(e)}
                        ></Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-1" controlId="">
                      <Form.Label column sm="8">
                        Hide Product Count
                      </Form.Label>
                      <Col sm="4">
                        <Form.Select
                          type="text"
                          name="hideProductCount"
                          value={this.state.theme.hideProductCount}
                          required
                          placeholder=""
                          onChange={(e) => this.handleInputChange(e)}
                        >
                          <option value=""></option>
                          <option value="false">false</option>
                          <option value="true">true</option>
                        </Form.Select>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-1" controlId="">
                      <Form.Label column sm="8">
                        Show AllProducts ByMainCategory On HomePAge
                      </Form.Label>
                      <Col sm="4">
                        <Form.Select
                          type="text"
                          name="showAllProductsByMainCategoryOnHomePage"
                          value={
                            this.state.theme
                              .showAllProductsByMainCategoryOnHomePage
                          }
                          required
                          placeholder=""
                          onChange={(e) => this.handleInputChange(e)}
                        >
                          <option value=""></option>
                          <option value="false">false</option>
                          <option value="true">true</option>
                        </Form.Select>
                      </Col>
                    </Form.Group>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="border px-3 ">
                    <p className="text-center">
                      <b>Badge</b>
                    </p>
                    <Form.Group as={Row} className="mb-1" controlId="">
                      <Form.Label column sm="8">
                        Badge Background Color
                      </Form.Label>
                      <Col sm="4">
                        <Form.Select
                          type="text"
                          name="badgeBackgroundColor"
                          value={this.state.theme.badgeBackgroundColor}
                          required
                          placeholder=""
                          onChange={(e) => this.handleInputChange(e)}
                        >
                          <option value=""></option>
                          <option value="black">black</option>
                          <option value="white">white</option>
                          <option value="primary">primary</option>
                          <option value="secondary">secondary</option>
                          <option value="success">success</option>
                          <option value="warning">warning</option>
                          <option value="danger">danger</option>
                        </Form.Select>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-1" controlId="">
                      <Form.Label column sm="8">
                        Badge TextColor
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          type="text"
                          name="badgeTextColor"
                          value={this.state.theme.badgeTextColor}
                          required
                          placeholder=""
                          onChange={(e) => this.handleInputChange(e)}
                        ></Form.Control>
                      </Col>
                    </Form.Group>
                  </div>
                  <div className="border px-3 ">
                    <p className="text-center">
                      <b>Product Card</b>
                    </p>
                    <Form.Group as={Row} className="mb-1" controlId="">
                      <Form.Label column sm="8">
                        All Product To GetQuote
                      </Form.Label>
                      <Col sm="4">
                        <Form.Select
                          type="text"
                          name="allProductToGetQuote"
                          value={this.state.theme.allProductToGetQuote}
                          required
                          placeholder=""
                          onChange={(e) => this.handleInputChange(e)}
                        >
                          <option value=""></option>
                          <option value="true">true</option>
                          <option value="false">false</option>
                        </Form.Select>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-1" controlId="">
                      <Form.Label column sm="8">
                        Show Product SingleView In Newpage
                      </Form.Label>
                      <Col sm="4">
                        <Form.Select
                          type="text"
                          name="showProductSingleViewInNewpage"
                          value={
                            this.state.theme.showProductSingleViewInNewpage
                          }
                          required
                          placeholder=""
                          onChange={(e) => this.handleInputChange(e)}
                        >
                          <option value=""></option>
                          <option value="true">true</option>
                          <option value="false">false</option>
                        </Form.Select>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-1" controlId="">
                      <Form.Label column sm="8">
                        ProductCard BackgroundColor
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          type="text"
                          name="productCardBackgroundColor"
                          value={this.state.theme.productCardBackgroundColor}
                          required
                          placeholder=""
                          onChange={(e) => this.handleInputChange(e)}
                        ></Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-1" controlId="">
                      <Form.Label column sm="8">
                        ProductCard TextColor
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          type="text"
                          name="productCardTextColor"
                          value={this.state.theme.productCardTextColor}
                          required
                          placeholder=""
                          onChange={(e) => this.handleInputChange(e)}
                        ></Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-1" controlId="">
                      <Form.Label column sm="8">
                        ProductCard Border Color
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          type="text"
                          name="productCardBorderColor"
                          value={this.state.theme.productCardBorderColor}
                          required
                          placeholder=""
                          onChange={(e) => this.handleInputChange(e)}
                        ></Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-1" controlId="">
                      <Form.Label column sm="8">
                        Product Name Height OnProductCard
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          type="text"
                          name="productNameHeightOnProductCard"
                          value={
                            this.state.theme.productNameHeightOnProductCard
                          }
                          required
                          placeholder=""
                          onChange={(e) => this.handleInputChange(e)}
                        ></Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-1" controlId="">
                      <Form.Label column sm="8">
                        ProductName Alignment OnProductCard
                      </Form.Label>
                      <Col sm="4">
                        <Form.Select
                          type="text"
                          name="productNameAlignOnProductCard "
                          value={this.state.theme.productNameAlignOnProductCard}
                          required
                          placeholder=""
                          onChange={(e) => this.handleInputChange(e)}
                        >
                          <option value=""></option>
                          <option value="start">start</option>
                          <option value="center">center</option>
                          <option value="end">end</option>
                        </Form.Select>
                      </Col>
                    </Form.Group>
                  </div>
                  <div className="border p-2">
                    <p className="text-center">
                      <b>Products List View Settings</b>
                    </p>
                    <Form.Group as={Row} className="mb-1" controlId="">
                      <Form.Label column sm="8">
                        Text Color of Filter panel
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          type="text"
                          name="filtersTextColor"
                          value={this.state.theme.filtersTextColor}
                          required
                          placeholder=""
                          onChange={(e) => this.handleInputChange(e)}
                        ></Form.Control>
                      </Col>
                    </Form.Group>
                  </div>
                  <div className="border p-2">
                    <p className="text-center">
                      <b>Others</b>
                    </p>
                    <Form.Group as={Row} className="mb-1" controlId="">
                      <Form.Label column sm="8">
                        Terms And Conditions on Checkout
                      </Form.Label>
                      <Col sm="4">
                        <Form.Check
                          name="checkTermsAndConditionsOnCheckOut"
                          checked={
                            this.state.theme
                              .checkTermsAndConditionsOnCheckOut == true
                          }
                          label=""
                          onChange={(e) => this.handleInputChange(e)}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-1" controlId="">
                      <Form.Label column sm="8">
                        Hide Filters
                      </Form.Label>
                      <Col sm="4">
                        <Form.Check
                          name="hideFilters"
                          checked={this.state.theme.hideFilters == true}
                          label=""
                          onChange={(e) => this.handleInputChange(e)}
                        />
                      </Col>
                    </Form.Group>
                  </div>
                </Col>
              </Row>
              <Row
                className="m-2 p-2 bg-light border border-dark"
                style={{ fontSize: "9pt" }}
              >
                <Col md={6} className="border p-3 border border-dark">
                  <div>
                    <h4> Desktop - Settings</h4>

                    <div className="border p-2">
                      <p className="text-center">
                        <b>Main & Sub Nav Bars</b>
                      </p>

                      <Form.Group as={Row} className="mb-1" controlId="">
                        <Form.Label column sm="8">
                          Hide ShopByCategory Menu
                        </Form.Label>
                        <Col sm="4">
                          <Form.Select
                            type="text"
                            name="hideShopByCategoryMenu"
                            value={this.state.theme.hideShopByCategoryMenu}
                            required
                            placeholder=""
                            onChange={(e) => this.handleInputChange(e)}
                          >
                            <option value=""></option>
                            <option value="true">true</option>
                            <option value="false">false</option>
                          </Form.Select>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1" controlId="">
                        <Form.Label column sm="8">
                          Show TopStrip On MainNavBar
                        </Form.Label>
                        <Col sm="4">
                          <Form.Select
                            type="text"
                            name="showTopStripOnMainNavBar"
                            value={this.state.theme.showTopStripOnMainNavBar}
                            required
                            placeholder=""
                            onChange={(e) => this.handleInputChange(e)}
                          >
                            <option value=""></option>
                            <option value="true">true</option>
                            <option value="false">false</option>
                          </Form.Select>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1" controlId="">
                        <Form.Label column sm="8">
                          Show GetQuote OnMenu
                        </Form.Label>
                        <Col sm="4">
                          <Form.Select
                            type="text"
                            name="showGetQuoteOnMenu"
                            value={this.state.theme.showGetQuoteOnMenu}
                            required
                            placeholder=""
                            onChange={(e) => this.handleInputChange(e)}
                          >
                            <option value=""></option>
                            <option value="true">true</option>
                            <option value="false">false</option>
                          </Form.Select>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1" controlId="">
                        <Form.Label column sm="8">
                          Show AboutUs OnMenu
                        </Form.Label>
                        <Col sm="4">
                          <Form.Select
                            type="text"
                            name="showAboutUsOnMenu"
                            value={this.state.theme.showAboutUsOnMenu}
                            required
                            placeholder=""
                            onChange={(e) => this.handleInputChange(e)}
                          >
                            <option value=""></option>
                            <option value="true">true</option>
                            <option value="false">false</option>
                          </Form.Select>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1" controlId="">
                        <Form.Label column sm="8">
                          Show PayCheckout OnMenu
                        </Form.Label>
                        <Col sm="4">
                          <Form.Select
                            type="text"
                            name="showPayCheckoutOnMenu"
                            value={this.state.theme.showPayCheckoutOnMenu}
                            required
                            placeholder=""
                            onChange={(e) => this.handleInputChange(e)}
                          >
                            <option value=""></option>
                            <option value="true">true</option>
                            <option value="false">false</option>
                          </Form.Select>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="8">
                          Store Logo Image Width (in CM)
                        </Form.Label>
                        <Col sm="4">
                          <Form.Control
                            type="text"
                            min={0}
                            name="storeLogoImageWidth"
                            value={this.state.theme.storeLogoImageWidth}
                            required
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mb-1" controlId="">
                        <Form.Label column sm="8">
                          Hide Main Menu
                        </Form.Label>
                        <Col sm="4">
                          <Form.Select
                            type="text"
                            name="hideMenu"
                            value={this.state.theme.hideMenu}
                            required
                            placeholder=""
                            onChange={(e) => this.handleInputChange(e)}
                          >
                            <option value=""></option>
                            <option value="true">true</option>
                            <option value="false">false</option>
                          </Form.Select>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="8">
                          menuFontSize
                        </Form.Label>
                        <Col sm="4">
                          <Form.Control
                            type="text"
                            min={0}
                            name="menuFontSize "
                            value={this.state.theme.menuFontSize}
                            required
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1" controlId="">
                        <Form.Label column sm="8">
                          Menu NavBar Background Color
                        </Form.Label>
                        <Col sm="4">
                          <Form.Control
                            type="text"
                            name="menuNavBarbackgroundColor "
                            value={this.state.theme.menuNavBarbackgroundColor}
                            required
                            placeholder=""
                            onChange={(e) => this.handleInputChange(e)}
                          ></Form.Control>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1" controlId="">
                        <Form.Label column sm="8">
                          Menu Text Color
                        </Form.Label>
                        <Col sm="4">
                          <Form.Control
                            type="text"
                            name="menuTextColor "
                            value={this.state.theme.menuTextColor}
                            required
                            placeholder=""
                            onChange={(e) => this.handleInputChange(e)}
                          ></Form.Control>
                        </Col>
                      </Form.Group>
                    </div>
                    <div className="border p-2">
                      <p className="text-center">
                        <b>Home Page Settings </b>
                      </p>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="8">
                          Homepage Margin (in CM)
                        </Form.Label>
                        <Col sm="4">
                          <Form.Control
                            type="text"
                            min={0}
                            name="homeMarginSpace"
                            value={this.state.theme.homeMarginSpace}
                            required
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="8">
                          Homepage Padding (in CM)
                        </Form.Label>
                        <Col sm="4">
                          <Form.Control
                            type="text"
                            min={0}
                            name="homePaddingSpace "
                            value={this.state.theme.homePaddingSpace}
                            required
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="8">
                          Homepage Titles FontSize
                        </Form.Label>
                        <Col sm="4">
                          <Form.Control
                            type="text"
                            min={0}
                            name="homepageTitlesFontSize "
                            value={this.state.theme.homepageTitlesFontSize}
                            required
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                    </div>

                    <div className="border p-2">
                      <p className="text-center">
                        <b>Carousel</b>
                      </p>
                      <Form.Group as={Row} className="mb-1" controlId="">
                        <Form.Label column sm="8">
                          {" "}
                          Hide Carousel
                        </Form.Label>
                        <Col sm="4">
                          <Form.Select
                            type="text"
                            name="hideCarousel"
                            value={this.state.theme.hideCarousel}
                            required
                            placeholder=""
                            onChange={(e) => this.handleInputChange(e)}
                          >
                            <option value=""></option>
                            <option value="true">true</option>
                            <option value="false">false</option>
                          </Form.Select>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="8">
                          Carousel ImageHeight (in CM)
                        </Form.Label>
                        <Col sm="4">
                          <Form.Control
                            type="text"
                            min={0}
                            name="carouselImageHeight"
                            value={this.state.theme.carouselImageHeight}
                            required
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                    </div>

                    <div className="border p-2">
                      <p className="text-center">
                        <b>MainCategory</b>
                      </p>

                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="8">
                          Main Category Background Color
                        </Form.Label>
                        <Col sm="4">
                          <Form.Control
                            type="text"
                            name="mainCategoryBackgroundColor"
                            value={this.state.theme.mainCategoryBackgroundColor}
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="8">
                          Main Category Text Color
                        </Form.Label>
                        <Col sm="4">
                          <Form.Control
                            type="text"
                            name="mainCategoryTextColor"
                            value={this.state.theme.mainCategoryTextColor}
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="8">
                          Main Category Total Rows
                        </Form.Label>
                        <Col sm="4">
                          <Form.Control
                            type="text"
                            name="mainCategoryTotalRows"
                            value={this.state.theme.mainCategoryTotalRows}
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="8">
                          Main Category Name
                        </Form.Label>
                        <Col sm="4">
                          <Form.Control
                            type="text"
                            name="mainCategoryName"
                            value={this.state.theme.mainCategoryName}
                            required
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1" controlId="">
                        <Form.Label column sm="8">
                          Hide Main Category
                        </Form.Label>
                        <Col sm="4">
                          <Form.Select
                            type="text"
                            name="hideMainCategory"
                            value={this.state.theme.hideMainCategory}
                            required
                            placeholder=""
                            onChange={(e) => this.handleInputChange(e)}
                          >
                            <option value=""></option>
                            <option value="true">true</option>
                            <option value="false">false</option>
                          </Form.Select>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1" controlId="">
                        <Form.Label column sm="8">
                          MainCategory Name Height
                        </Form.Label>
                        <Col sm="4">
                          <Form.Control
                            type="text"
                            name="maincategoryNameHeight"
                            value={this.state.theme.maincategoryNameHeight}
                            required
                            placeholder=""
                            onChange={(e) => this.handleInputChange(e)}
                          ></Form.Control>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1" controlId="">
                        <Form.Label column sm="8">
                          SubCategory Name Height
                        </Form.Label>
                        <Col sm="4">
                          <Form.Control
                            type="text"
                            name="subcategoryNameHeight"
                            value={this.state.theme.subcategoryNameHeight}
                            required
                            placeholder=""
                            onChange={(e) => this.handleInputChange(e)}
                          ></Form.Control>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="8">
                          Slide Width in Main Category(in CM)
                        </Form.Label>
                        <Col sm="4">
                          <Form.Control
                            type="text"
                            min={0}
                            name="mainCategorySlideWidth"
                            value={this.state.theme.mainCategorySlideWidth}
                            required
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="8">
                          Main Category Card Width (in CM)
                        </Form.Label>
                        <Col sm="4">
                          <Form.Control
                            type="text"
                            min={0}
                            name="mainCategoryCardWidth"
                            value={this.state.theme.mainCategoryCardWidth}
                            required
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="8">
                          Maincategory Image Width (in CM)
                        </Form.Label>
                        <Col sm="4">
                          <Form.Control
                            type="text"
                            min={0}
                            name="maincategoryImageWidth"
                            value={this.state.theme.maincategoryImageWidth}
                            required
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="8">
                          MainCategory Image Height (in CM)
                        </Form.Label>
                        <Col sm="4">
                          <Form.Control
                            type="text"
                            min={0}
                            name="mainCategoryImageHeight"
                            value={this.state.theme.mainCategoryImageHeight}
                            required
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                    </div>
                    <div className="border p-2">
                      <p className="text-center">
                        <b>SubCategory </b>
                      </p>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="8">
                          Sub Category Background Color
                        </Form.Label>
                        <Col sm="4">
                          <Form.Control
                            type="text"
                            name="subCategoryBackgroundColor"
                            value={this.state.theme.subCategoryBackgroundColor}
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="8">
                          Sub Category Text Color
                        </Form.Label>
                        <Col sm="4">
                          <Form.Control
                            type="text"
                            name="subCategoryTextColor"
                            value={this.state.theme.subCategoryTextColor}
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="8">
                          Sub Category Total Rows
                        </Form.Label>
                        <Col sm="4">
                          <Form.Control
                            type="text"
                            name="subCategoryTotalRows"
                            value={this.state.theme.subCategoryTotalRows}
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="8">
                          Sub Category Name
                        </Form.Label>
                        <Col sm="4">
                          <Form.Control
                            type="text"
                            name="subCategoryName"
                            value={this.state.theme.subCategoryName}
                            required
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mb-1" controlId="">
                        <Form.Label column sm="8">
                          Hide Sub Category
                        </Form.Label>
                        <Col sm="4">
                          <Form.Select
                            type="text"
                            name="hideSubCategory"
                            value={this.state.theme.hideSubCategory}
                            required
                            placeholder=""
                            onChange={(e) => this.handleInputChange(e)}
                          >
                            <option value="true">true</option>
                            <option value="false">false</option>
                          </Form.Select>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="8">
                          Slide Width in Sub Category(in CM)
                        </Form.Label>
                        <Col sm="4">
                          <Form.Control
                            type="text"
                            min={0}
                            name="subCategorySlidesWidth"
                            value={this.state.theme.subCategorySlidesWidth}
                            required
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="8">
                          SubCategory CardWidth (in CM)
                        </Form.Label>
                        <Col sm="4">
                          <Form.Control
                            type="text"
                            min={0}
                            name="subCategoryCardWidth"
                            value={this.state.theme.subCategoryCardWidth}
                            required
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="8">
                          SubCategory Image Height (in CM)
                        </Form.Label>
                        <Col sm="4">
                          <Form.Control
                            type="text"
                            min={0}
                            name="subCategoryImageHeight"
                            value={this.state.theme.subCategoryImageHeight}
                            required
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                    </div>
                    <div className="border p-2">
                      <p className="text-center">
                        <b>Product Group </b>
                      </p>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="8">
                          Slide Width in Product Group (in CM)
                        </Form.Label>
                        <Col sm="4">
                          <Form.Control
                            type="text"
                            min={0}
                            name="productGropupSlidesWidth"
                            value={this.state.theme.productGropupSlidesWidth}
                            required
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="8">
                          Total Slides ToShow
                        </Form.Label>
                        <Col sm="4">
                          <Form.Control
                            type="text"
                            min={0}
                            name="slidesToShowInProductList"
                            value={this.state.theme.slidesToShowInProductList}
                            required
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="border p-2">
                    <p className="text-center">
                      <b>Product CARD</b>
                    </p>

                    <Form.Group as={Row} className="mb-1 " controlId="">
                      <Form.Label column sm="8">
                        Show Price OnCards when Hover
                      </Form.Label>
                      <Col sm="4">
                        <Form.Select
                          type="text"
                          name="showPriceOnCardswhenHover"
                          value={this.state.theme.showPriceOnCardswhenHover}
                          required
                          placeholder=""
                          onChange={(e) => this.handleInputChange(e)}
                        >
                          <option value=""></option>
                          <option value="false">false</option>
                          <option value="true">true</option>
                        </Form.Select>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-1 " controlId="">
                      <Form.Label column sm="8">
                        ProductCard Width (in CM)
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          type="text"
                          min={0}
                          name="productCardWidth"
                          value={this.state.theme.productCardWidth}
                          required
                          placeholder=""
                          autoComplete="off"
                          onChange={(e) => this.handleInputChange(e)}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-1 " controlId="">
                      <Form.Label column sm="8">
                        productCardImagewidth (in CM)
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          type="text"
                          min={0}
                          name="productCardImagewidth"
                          value={this.state.theme.productCardImagewidth}
                          required
                          placeholder=""
                          autoComplete="off"
                          onChange={(e) => this.handleInputChange(e)}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-1 " controlId="">
                      <Form.Label column sm="8">
                        ProductCard ImageHeight (in CM)
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          type="text"
                          min={0}
                          name="productCardImageHeight"
                          value={this.state.theme.productCardImageHeight}
                          required
                          placeholder=""
                          autoComplete="off"
                          onChange={(e) => this.handleInputChange(e)}
                        />
                      </Col>
                    </Form.Group>
                    {/* <Form.Group as={Row} className="mb-1 " controlId="">
                      <Form.Label column sm="8">
                        Number of Cards On ProductGroup ToShow
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          type="number"
                          min={0}
                          name="noOfCardsOnProductGroupToShow"
                          value={this.state.theme.noOfCardsOnProductGroupToShow}
                          required
                          placeholder=""
                          autoComplete="off"
                          onChange={(e) => this.handleInputChange(e)}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-1 " controlId="">
                      <Form.Label column sm="8">
                        Number of Cards On ProductList ToShow
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          type="number"
                          min={0}
                          name="noOfCardsOnProductListToShow"
                          value={this.state.theme.noOfCardsOnProductListToShow}
                          required
                          placeholder=""
                          autoComplete="off"
                          onChange={(e) => this.handleInputChange(e)}
                        />
                      </Col>
                    </Form.Group> */}

                    <Form.Group as={Row} className="mb-1 " controlId="">
                      <Form.Label column sm="8">
                        Product Price Font Size
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          type="text"
                          min={0}
                          name="priceFontSizeOnProductCard"
                          value={this.state.theme.priceFontSizeOnProductCard}
                          required
                          placeholder=""
                          autoComplete="off"
                          onChange={(e) => this.handleInputChange(e)}
                        />
                      </Col>
                    </Form.Group>
                  </div>
                  <div className="border p-3">
                    <p className="text-center">
                      <b>Product Single View- DESKTOP</b>
                    </p>
                    <Form.Group as={Row} className="mb-1 " controlId="">
                      <Form.Label column sm="8">
                        Product SingleView Card/Image Width (in CM)
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          type="text"
                          min={0}
                          name="productSingleViewProductCardWidth"
                          value={
                            this.state.theme.productSingleViewProductCardWidth
                          }
                          required
                          placeholder=""
                          autoComplete="off"
                          onChange={(e) => this.handleInputChange(e)}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-1 " controlId="">
                      <Form.Label column sm="8">
                        Product SingleView Image Height (in CM)
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          type="text"
                          min={0}
                          name="productSingleViewProductCardImageHeight"
                          value={
                            this.state.theme
                              .productSingleViewProductCardImageHeight
                          }
                          required
                          placeholder=""
                          autoComplete="off"
                          onChange={(e) => this.handleInputChange(e)}
                        />
                      </Col>
                    </Form.Group>
                  </div>
                </Col>
                <Col xs={6} className="my-0 border border-dark">
                  <div className="border p-3">
                    <h4> Mobile-Settings </h4>

                    <div className="border p-2">
                      <p className="text-center">
                        <b>Main Nav Bar </b>
                      </p>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="8">
                          Invert MenuIcon On Mobile (in %)
                        </Form.Label>
                        <Col sm="4">
                          <Form.Control
                            type="text"
                            min={0}
                            name="invertMenuIconOnMobile"
                            value={this.state.theme.invertMenuIconOnMobile}
                            required
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="8">
                          Homepage Titles Font Size (in CM)
                        </Form.Label>
                        <Col sm="4">
                          <Form.Control
                            type="text"
                            min={0}
                            name="homepageTitlesFontSizeMobile"
                            value={
                              this.state.theme.homepageTitlesFontSizeMobile
                            }
                            required
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="8">
                          Store Logo Image Width (in CM)
                        </Form.Label>
                        <Col sm="4">
                          <Form.Control
                            type="text"
                            min={0}
                            name="storeLogoImageWidthMobile"
                            value={this.state.theme.storeLogoImageWidthMobile}
                            required
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                    </div>

                    <div className="border p-2">
                      <p className="text-center">
                        <b>Carousel </b>
                      </p>
                      <Form.Group as={Row} className="mb-1" controlId="">
                        <Form.Label column sm="8">
                          Hide Carousel
                        </Form.Label>
                        <Col sm="4">
                          <Form.Select
                            type="text"
                            name="hideCarouselMobile"
                            value={this.state.theme.hideCarouselMobile}
                            required
                            placeholder=""
                            onChange={(e) => this.handleInputChange(e)}
                          >
                            <option value="true">true</option>
                            <option value="false">false</option>
                          </Form.Select>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="8">
                          Carousel ImageHeight (in CM)
                        </Form.Label>
                        <Col sm="4">
                          <Form.Control
                            type="text"
                            min={0}
                            name="carouselImageHeightMobile"
                            value={this.state.theme.carouselImageHeightMobile}
                            required
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                    </div>

                    <div className="border p-2">
                      <p className="text-center">
                        <b>Main Category </b>
                      </p>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="8">
                          Main Category Name
                        </Form.Label>
                        <Col sm="4">
                          <Form.Control
                            type="text"
                            name="mainCategoryNameMobile"
                            value={this.state.theme.mainCategoryNameMobile}
                            required
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1" controlId="">
                        <Form.Label column sm="8">
                          Hide Main Category
                        </Form.Label>
                        <Col sm="4">
                          <Form.Select
                            type="text"
                            name="hideMainCategoryMobile"
                            value={this.state.theme.hideMainCategoryMobile}
                            required
                            placeholder=""
                            onChange={(e) => this.handleInputChange(e)}
                          >
                            <option value=""></option>
                            <option value="true">true</option>
                            <option value="false">false</option>
                          </Form.Select>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1" controlId="">
                        <Form.Label column sm="8">
                          Show AllMainCategories On HomePage
                        </Form.Label>
                        <Col sm="4">
                          <Form.Select
                            type="text"
                            name="showAllMainCategoryOnHomePageMobile"
                            value={
                              this.state.theme
                                .showAllMainCategoryOnHomePageMobile
                            }
                            required
                            placeholder=""
                            onChange={(e) => this.handleInputChange(e)}
                          >
                            <option value=""></option>
                            <option value="true">true</option>
                            <option value="false">false</option>
                          </Form.Select>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="8">
                          Maincategory Name Height (in CM)
                        </Form.Label>
                        <Col sm="4">
                          <Form.Control
                            type="text"
                            min={0}
                            name="maincategoryNameHeightMobile"
                            value={
                              this.state.theme.maincategoryNameHeightMobile
                            }
                            required
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="8">
                          Subcategory Name Height (in CM)
                        </Form.Label>
                        <Col sm="4">
                          <Form.Control
                            type="text"
                            min={0}
                            name="subcategoryNameHeightMobile"
                            value={this.state.theme.subcategoryNameHeightMobile}
                            required
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="8">
                          Slide Width in Main Category (in CM)
                        </Form.Label>
                        <Col sm="4">
                          <Form.Control
                            type="text"
                            min={0}
                            name="mainCategorySlideWidthMobile"
                            value={
                              this.state.theme.mainCategorySlideWidthMobile
                            }
                            required
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="8">
                          Main Category Card Width (in CM)
                        </Form.Label>
                        <Col sm="4">
                          <Form.Control
                            type="text"
                            min={0}
                            name="mainCategoryCardWidthMobile"
                            value={this.state.theme.mainCategoryCardWidthMobile}
                            required
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="8">
                          Maincategory Image Width (in CM)
                        </Form.Label>
                        <Col sm="4">
                          <Form.Control
                            type="text"
                            min={0}
                            name="maincategoryImageWidthMobile"
                            value={
                              this.state.theme.maincategoryImageWidthMobile
                            }
                            required
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="8">
                          MainCategory Image Height (in CM)
                        </Form.Label>
                        <Col sm="4">
                          <Form.Control
                            type="text"
                            min={0}
                            name="mainCategoryImageHeightMobile"
                            value={
                              this.state.theme.mainCategoryImageHeightMobile
                            }
                            required
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                    </div>
                    <div className="border p-2">
                      <p className="text-center">
                        <b>Sub Category </b>
                      </p>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="8">
                          Sub Category Name
                        </Form.Label>
                        <Col sm="4">
                          <Form.Control
                            type="text"
                            name="subCategoryNameMobile"
                            value={this.state.theme.subCategoryNameMobile}
                            required
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mb-1" controlId="">
                        <Form.Label column sm="8">
                          Hide Sub Category
                        </Form.Label>
                        <Col sm="4">
                          <Form.Select
                            type="text"
                            name="hideSubCategoryMobile"
                            value={this.state.theme.hideSubCategoryMobile}
                            required
                            placeholder=""
                            onChange={(e) => this.handleInputChange(e)}
                          >
                            <option value=""></option>
                            <option value="true">true</option>
                            <option value="false">false</option>
                          </Form.Select>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="8">
                          Slide Width in Sub Category (in CM)
                        </Form.Label>
                        <Col sm="4">
                          <Form.Control
                            type="text"
                            min={0}
                            name="subCategorySlideWidthMobile"
                            value={this.state.theme.subCategorySlideWidthMobile}
                            required
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="8">
                          SubCategory CardWidth (in CM)
                        </Form.Label>
                        <Col sm="4">
                          <Form.Control
                            type="text"
                            min={0}
                            name="subCategoryCardWidthMobile"
                            value={this.state.theme.subCategoryCardWidthMobile}
                            required
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="8">
                          SubCategory Image Height (in CM)
                        </Form.Label>
                        <Col sm="4">
                          <Form.Control
                            type="text"
                            min={0}
                            name="subCategoryImageHeightMobile"
                            value={
                              this.state.theme.subCategoryImageHeightMobile
                            }
                            required
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                    </div>
                    <div className="border p-2">
                      <p className="text-center">
                        <b>Product Group </b>
                      </p>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="8">
                          Slide Width in Product Group (in CM)
                        </Form.Label>
                        <Col sm="4">
                          <Form.Control
                            type="text"
                            min={0}
                            name="productGropupSlideWidthMobile"
                            value={
                              this.state.theme.productGropupSlideWidthMobile
                            }
                            required
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="border p-3">
                    <p className="text-center">
                      <b>Product CARD - Mobile</b>
                    </p>
                    <Form.Group as={Row} className="mb-1 " controlId="">
                      <Form.Label column sm="8">
                        Product Price Font Size (in CM)
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          type="text"
                          min={0}
                          name="priceFontSizeOnProductCardMobile"
                          value={
                            this.state.theme.priceFontSizeOnProductCardMobile
                          }
                          required
                          placeholder=""
                          autoComplete="off"
                          onChange={(e) => this.handleInputChange(e)}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-1 " controlId="">
                      <Form.Label column sm="8">
                        ProductCard Width (in CM)
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          type="text"
                          min={0}
                          name="productCardWidthMobile"
                          value={this.state.theme.productCardWidthMobile}
                          required
                          placeholder=""
                          autoComplete="off"
                          onChange={(e) => this.handleInputChange(e)}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-1 " controlId="">
                      <Form.Label column sm="8">
                        ProductCard Image Height (in CM)
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          type="text"
                          min={0}
                          name="productCardImageHeightMobile"
                          value={this.state.theme.productCardImageHeightMobile}
                          required
                          placeholder=""
                          autoComplete="off"
                          onChange={(e) => this.handleInputChange(e)}
                        />
                      </Col>
                    </Form.Group>
                  </div>
                  <div className="border p-3">
                    <p className="text-center">
                      <b>Product Single View- Mobile</b>
                    </p>
                    <Form.Group as={Row} className="mb-1 " controlId="">
                      <Form.Label column sm="8">
                        Product SingleView Card/Image Width (in CM)
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          type="text"
                          min={0}
                          name="productSingleViewProductCardWidthMobile"
                          value={
                            this.state.theme
                              .productSingleViewProductCardWidthMobile
                          }
                          required
                          placeholder=""
                          autoComplete="off"
                          onChange={(e) => this.handleInputChange(e)}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-1 " controlId="">
                      <Form.Label column sm="8">
                        Product SingleView ImageHeight (in CM)
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          type="text"
                          min={0}
                          name="productSingleViewProductCardImageHeightMobile"
                          value={
                            this.state.theme
                              .productSingleViewProductCardImageHeightMobile
                          }
                          required
                          placeholder=""
                          autoComplete="off"
                          onChange={(e) => this.handleInputChange(e)}
                        />
                      </Col>
                    </Form.Group>
                  </div>
                </Col>
              </Row>
            </Modal.Body>

            <Modal.Footer className="bg-light">
              {/* <Button variant="primary" size="lg" onClick={this.Apply}>
                Apply
              </Button> */}
              <Form.Group>
                <Button variant="primary" size="lg" type="submit">
                  Save
                </Button>
              </Form.Group>

              <Button variant="danger" size="lg" onClick={this.props.onHide}>
                Close
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }
}
