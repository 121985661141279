import React, { Component, useContext } from "react";
import CartContext from "./CartContext";
import { OrderDetails } from "./OrderDetails";
import { OrderDetailsMobile } from "./OrderDetailsMobile";
import { SideMenu } from "./SideMenu";
import { BiSave, BiRefresh, BiUpload, BiDownload } from "react-icons/bi";
import Fuse from "fuse.js";
import swal from "sweetalert";
import axios from "axios";
import Swal from "sweetalert2";
import { LoadSpinner } from "./LoadSpinner";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { useRef } from "react";
import { useState } from "react";
import { MessageBox } from "./MessageBox";

import {
  InputGroup,
  Tabs,
  Tab,
  Stack,
  FormControl,
  Button,
  ButtonToolbar,
  Modal,
  Form,
  Row,
  Col,
  FloatingLabel,
  Nav,
  Table,
  Navbar,
  Card,
} from "react-bootstrap";

import { ProductAddOrEditModal } from "./ProductAddOrEditModal";
import { EditProductModal } from "./ProductAddOrEditModal";

import Select from "react-select";
import {} from "react-bootstrap";

export class QuotationRequest extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      ordersDetail: [],
      selectedOrder: -1,
      selectedOrderRow: "",
      filteredOrdersDetail: [],
      showOrderDetailsModal: false,
      loadSpinnerOpen: false,
      firstload: false,
      quotationTypes: [],
      refreshTab: false,
      ShowNewRequestTabRefresh: true,
      ShowQuotationTabRefresh: false,
      ShowPaymentTabRefresh: false,
      ShowProduceTabRefresh: false,
      ShowShipTabRefresh: false,
      ShowDeliveryTabRefresh: false,
      ShowHistoryTabRefresh: false,
    };
  }
  refreshTab = () => {
    this.setState({ refreshTab: true }, () => {
      this.setState({ refreshTab: false });
    });
  };

  ShowSelectedTab = (k) => {
    this.setState({ ShowNewRequestTabRefresh: false });
    this.setState({ ShowQuotationTabRefresh: false });
    this.setState({ ShowPaymentTabRefresh: false });
    this.setState({ ShowProduceTabRefresh: false });
    this.setState({ ShowShipTabRefresh: false });
    this.setState({ ShowDeliveryTabRefresh: false });
    this.setState({ ShowHistoryTabRefresh: false });

    if (k == "NewRequest") this.setState({ ShowNewRequestTabRefresh: true });
    if (k == "Quotation") this.setState({ ShowQuotationTabRefresh: true });
    if (k == "Payment") this.setState({ ShowPaymentTabRefresh: true });
    if (k == "Produce") this.setState({ ShowProduceTabRefresh: true });
    if (k == "Ship") this.setState({ ShowShipTabRefresh: true });
    if (k == "Delivery") this.setState({ ShowDeliveryTabRefresh: true });
    if (k == "History") this.setState({ ShowHistoryTabRefresh: true });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <>
          {this.state.loadSpinnerOpen === true ? (
            <LoadSpinner open="true"></LoadSpinner>
          ) : (
            ""
          )}
          <div className="px-0 bg-white">
            <Navbar className="secondarythemecolor position-sticky fixed-top px-1 m-0 p-1 text-center border-3 border-dark">
              <Navbar.Collapse className="justify-content-end m-0 p-0 ">
                <Nav>
                  <h4 className=""> Quotation Workflow Management</h4>
                </Nav>
              </Navbar.Collapse>
              <Navbar.Collapse className="justify-content-end m-0 p-0 ">
                <Nav>
                  <ButtonToolbar className="text-start align-middle">
                    &nbsp;
                    <Button
                      className="mainthemecolor"
                      onClick={() => this.Reload()}
                    >
                      <BiRefresh size="20"></BiRefresh>
                      Refresh
                    </Button>
                    &nbsp;
                  </ButtonToolbar>
                </Nav>
              </Navbar.Collapse>
            </Navbar>

            <Tabs
              id="controlled-tab-example"
              activeKey={this.state.key}
              onSelect={(k) => {
                this.setState({ key: k });
                this.ShowSelectedTab(k);
              }}
              className="mb-3 bg-light fw-bold"
              fill
              style={{ fontSize: "22px", color: "yellow" }}
            >
              <Tab eventKey="NewRequest" title="1.New Request">
                {this.state.ShowNewRequestTabRefresh == true ? (
                  <>
                    <QuotationRequestTable CurrentState="NewRequest"></QuotationRequestTable>
                  </>
                ) : (
                  <></>
                )}
              </Tab>
              <Tab eventKey="Quotation" title="2.Quotation ">
                {this.state.ShowQuotationTabRefresh == true ? (
                  <>
                    <QuotationRequestTable CurrentState="Quotation"></QuotationRequestTable>
                  </>
                ) : (
                  <></>
                )}
              </Tab>
              <Tab eventKey="Payment" title="3.Payment">
                {this.state.ShowPaymentTabRefresh == true ? (
                  <>
                    <QuotationRequestTable CurrentState="Payment"></QuotationRequestTable>
                  </>
                ) : (
                  <></>
                )}
              </Tab>
              <Tab eventKey="Produce" title="4.Produce">
                {this.state.ShowProduceTabRefresh == true ? (
                  <>
                    <QuotationRequestTable CurrentState="Produce"></QuotationRequestTable>
                  </>
                ) : (
                  <></>
                )}
              </Tab>
              <Tab eventKey="Ship" title="5.Ship">
                {this.state.ShowShipTabRefresh == true ? (
                  <>
                    <QuotationRequestTable CurrentState="Ship"></QuotationRequestTable>
                  </>
                ) : (
                  <></>
                )}
              </Tab>
              <Tab eventKey="Delivery" title="6.Delivery">
                {this.state.ShowDeliveryTabRefresh == true ? (
                  <>
                    <QuotationRequestTable CurrentState="Delivery"></QuotationRequestTable>
                  </>
                ) : (
                  <></>
                )}
              </Tab>
              <Tab eventKey="History" title="7.History">
                {this.state.ShowHistoryTabRefresh == true ? (
                  <>
                    <QuotationRequestTable CurrentState="History"></QuotationRequestTable>
                  </>
                ) : (
                  <></>
                )}
              </Tab>
            </Tabs>
          </div>
        </>
      </>
    );
  }
}

export class QuotationRequestTable extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      ordersDetail: [],
      selectedOrder: -1,
      selectedOrderRow: "",
      filteredOrdersDetail: [],
      showOrderDetailsModal: false,
      loadSpinnerOpen: false,
      firstload: false,
      quotationTypes: [],
    };
  }
  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };
  Reload = () => {
    // this.setState({ RefreshPage: true }, () => {});
    this.GetOrders();
  };

  GetQuoteStatusTypes = () => {
    fetch(process.env.REACT_APP_API + "StoreManager/GetQuoteStatusTypes/")
      .then((response) => response.json())
      .then((data) => {
        this.setState({ quotationTypes: data });
      })
      .catch((error) => {});
  };

  GetStatusName = (id) => {
    var result = this.state.quotationTypes.filter((f) => f.id == id);
    if (result.length > 0) return result[0].statusName;
    return "";
  };

  GetOrders = () => {
    this.setState({ ordersDetail: [] }, () => {
      this.ShowLoadSpinner();
      fetch(
        process.env.REACT_APP_API +
          "StoreManager/GetQuotationRequestsByState/" +
          this.context.storeId +
          "/" +
          this.props.CurrentState
      )
        .then((response) => response.json())
        .then((data) => {
          this.setState({ ordersDetail: data });
          this.setState({ filteredOrdersDetail: data });
          this.HideLoadSpinner();
        })
        .catch((error) => {
          this.HideLoadSpinner();
        });
    });
  };

  OnOrderRowClick = (e, order) => {
    this.setState({ selectedOrder: order });
    this.setState({ showOrderDetailsModal: true });
  };
  ModalClose = () => {
    this.setState({ showOrderDetailsModal: false }, () => {});
    this.Reload();
  };

  onSearchChange = (query) => {
    this.setState({ reload: false });
    this.setState({ searchquery: query });
    if (!query) {
      this.setState({ filteredOrdersDetail: this.state.ordersDetail }, () => {
        this.setState({ reload: true });
      });
      return;
    }
    const fuse = new Fuse(this.state.ordersDetail, {
      keys: ["orderId", "deliveryAddress", "customerName", "mobileNo"],
      threshold: 0.0,
      ignoreLocation: true,
      findAllMatches: true,
      includeMatches: true,
      distance: 120,
    });
    const result = fuse.search(query);
    const finalResult = [];
    if (result.length) {
      result.forEach((item) => {
        finalResult.push(item.item);
      });
      this.setState({ filteredOrdersDetail: finalResult.slice(0, 15) }, () => {
        this.setState({ reload: true });
      });
    } else {
      this.setState({ filteredOrdersDetail: [] });
    }
  };

  enableDeleteButtonMenu = () => {
    if (this.context.UserRoles.PRODUCTADMIN == this.context.userData.roleId) {
      return false;
    } else {
      return true;
    }
  };

  OnDeleteOrder = (e, order) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    };

    fetch(
      process.env.REACT_APP_API +
        "StoreManager/DeleteAnCustomerOrder/" +
        order.id,
      requestOptions
    )
      .then((res) => {
        if (res.status != 200) {
          this.Reload();
          alert("not deleted");
          return;
        }
        this.Reload();
        Swal.fire({
          title: "Status",
          text: "Deleted Successfully",
          // confirmButtonText: "yes",

          timer: 1000,
        }).then((result) => {});
      })
      .catch((error) => {
        alert("OnDelete Failed" + error);
      });
  };

  OnOrderRowSelect = (e, order) => {
    this.setState({ selectedOrderRow: "" }, () => {
      this.setState({ selectedOrderRow: order });
    });
  };

  onSearchChange = (query) => {
    this.setState({ reload: false });
    this.setState({ searchquery: query });
    if (!query) {
      this.setState({ filteredOrdersDetail: this.state.ordersDetail }, () => {
        this.setState({ reload: true });
      });
      return;
    }
    const fuse = new Fuse(this.state.ordersDetail, {
      keys: ["orderId", "deliveryAddress", "customerName", "mobileNo"],
      threshold: 0.0,
      ignoreLocation: true,
      findAllMatches: true,
      includeMatches: true,
      distance: 120,
    });
    const result = fuse.search(query);
    const finalResult = [];
    if (result.length) {
      result.forEach((item) => {
        finalResult.push(item.item);
      });
      this.setState({ filteredOrdersDetail: finalResult.slice(0, 15) }, () => {
        this.setState({ reload: true });
      });
    } else {
      this.setState({ filteredOrdersDetail: [] });
    }
  };

  GetDateDifference = (createddate) => {
    var startTime = new Date();
    var endTime = new Date(createddate);
    var diff = startTime - endTime;
    var minutes = Math.floor(diff / 1000 / 60);
    var hours = Math.floor(minutes / 60, 0);
    var days = Math.floor(hours / 24, 0);
    var daysstring = days > 0 ? days + "d : " : " ";
    var hoursstring = hours > 0 ? (hours % 24) + "h : " : " ";

    return daysstring + hoursstring + (minutes % 60) + " m";
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    if (this.state.firstload == false) {
      this.state.firstload = true;
      this.GetOrders();
      this.GetQuoteStatusTypes();
    }
  }

  render() {
    return (
      <>
        <div>
          {this.state.loadSpinnerOpen === true ? (
            <LoadSpinner open="true"></LoadSpinner>
          ) : (
            ""
          )}
          <div>
            <Stack direction="horizontal" gap={3} className="py-2">
              <b>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  class="bi bi-search"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                </svg>
              </b>
              <FormControl
                type="search"
                placeholder="Search by Phoneno,CustomerName,OrderNo... "
                className="text-primary fw-bold bg-white "
                aria-label="Search"
                onChange={(e) => this.onSearchChange(e.target.value)}
              />
            </Stack>
            <Table className="text-center" size="sm">
              <thead style={{ fontSize: "14px" }}>
                <th>#</th>
                <th>Received On</th>
                <th>Quotation ID</th>
                <th className="text-center">Customer</th>
                <th className="text-center">Product</th>
                <th className="text-center">Qty</th>
                <th className="text-center">Purpose</th>
                <th className="text-center">Quotation</th>
                <th className="text-center">Options</th>
              </thead>
              <tbody className="text-center" style={{ fontSize: "14px" }}>
                {this.state.filteredOrdersDetail
                  .sort((a, b) => (a.created > b.created ? 1 : -1))
                  .map((order, i) => (
                    <>
                      <tr
                        className=" text-start border-2 border-secondary"
                        onDoubleClick={(e) => this.OnOrderRowClick(e, order)}
                      >
                        <td>{i + 1}</td>
                        <td>
                          {this.context.GetInDateFormat(
                            new Date(order.created)
                          )}
                        </td>
                        <td>
                          <h4>
                            <b>{order.quoteNumber}</b>
                          </h4>
                          AssignedTo:{" "}
                          <span className="CornersRounded border border-primary ">
                            &nbsp;{order.assignedTo}&nbsp;
                          </span>{" "}
                        </td>
                        <td className="border ">
                          <h5>
                            Name:
                            <b>{order.customerName}</b>
                          </h5>
                          <h5>
                            Contact: <b>{order.contactNumber}</b>
                          </h5>
                          <h5>
                            EmailId: <b>{order.emailId}</b>
                          </h5>
                        </td>
                        <td className="border">
                          Category Name:{" "}
                          <span className="CornersRounded border border-dark">
                            &nbsp;{order.categoryName}&nbsp;
                          </span>{" "}
                          <br />
                          Product Name:
                          <b>
                            <span className="CornersRounded border border-dark bg-danger text-white">
                              &nbsp;{order.productName}&nbsp;
                            </span>{" "}
                          </b>
                          <br />
                          Detailed Requirement:{" "}
                          <b>{order.detailedRequirement}</b>
                          &nbsp;
                        </td>
                        <td className="border text-center">
                          <h4>
                            <span className="CornersRounded border border-dark bg-warning text-dark">
                              &nbsp;{order.qty}&nbsp;
                            </span>
                          </h4>
                        </td>
                        <td className="border">
                          <Row>
                            <Col>Purpose: </Col>
                            <Col>
                              <span className="CornersRounded border border-dark">
                                &nbsp;{order.purposeOfProcurement} &nbsp;
                              </span>
                            </Col>
                          </Row>
                          <Row>
                            <Col>Language: </Col>
                            <Col>
                              <span className="CornersRounded border border-dark">
                                &nbsp;{order.preferredLanguage}&nbsp;
                              </span>{" "}
                            </Col>
                          </Row>
                          <Row>
                            <Col>Timeline: </Col>
                            <Col>
                              <span className="CornersRounded border border-dark">
                                &nbsp;{order.deliveryTimeline}&nbsp;
                              </span>
                            </Col>
                          </Row>
                        </td>

                        <td className="border m-0 p-0">
                          <Row className="m-0 p-0">
                            <Col>Status:</Col>
                            <Col>
                              <span className="CornersRounded border border-dark bg-warning text-dark">
                                &nbsp; {this.GetStatusName(order.quoteStatus)}
                                &nbsp;
                              </span>
                            </Col>
                          </Row>
                          <Row className="m-0 p-0">
                            <Col>Importance: </Col>
                            <Col>
                              <span className="CornersRounded border border-dark ">
                                &nbsp;
                                {order.requestSeverityType == 1
                                  ? "HOT"
                                  : order.requestSeverityType == 2
                                  ? "WARM"
                                  : order.requestSeverityType == 3
                                  ? "COLD"
                                  : ""}
                                &nbsp;
                              </span>{" "}
                            </Col>
                          </Row>
                          <Row className="m-0 p-0">
                            <Col>Quotation Amount: </Col>
                            <Col>
                              <span className="CornersRounded border border-dark bg-primary text-white">
                                &nbsp; {order.quotationTotalAmount}&nbsp;
                              </span>
                            </Col>
                          </Row>
                          <Row className="m-0 p-0">
                            <Col>Tot.Amount Received:</Col>
                            <Col>
                              <span className="CornersRounded border border-dark bg-success text-white">
                                &nbsp;
                                {order.amountReceived}
                                &nbsp;
                              </span>
                            </Col>
                          </Row>
                          <Row className="m-0 p-0">
                            <Col>Payment Status: </Col>
                            <Col>
                              <b>
                                <span className="CornersRounded border border-danger ">
                                  &nbsp;
                                  {order.paymentStatus == 1
                                    ? "Paid"
                                    : order.paymentStatus == 2
                                    ? "UnPaid"
                                    : order.paymentStatus == 3
                                    ? "PartiallyPaid"
                                    : ""}
                                  &nbsp;
                                </span>
                              </b>
                            </Col>
                          </Row>
                        </td>

                        <td>
                          <Button
                            className="mx-2"
                            onClick={(e) => this.OnOrderRowClick(e, order)}
                          >
                            Open
                          </Button>

                          <Button
                            className="my-2"
                            hidden={this.enableDeleteButtonMenu()}
                            variant="danger"
                            onClick={(e) => {
                              if (
                                window.confirm(
                                  " Are you sure that you want to Delete?"
                                )
                              ) {
                                this.OnDeleteOrder(e, order);
                              }
                            }}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    </>
                  ))}
              </tbody>
            </Table>
          </div>
          {this.state.showOrderDetailsModal === true ? (
            <>
              {window.innerWidth >= 1200 ? (
                <>
                  <QuotationRequestDetails
                    show={this.state.showOrderDetailsModal}
                    onHide={this.ModalClose}
                    orderDetail={this.state.selectedOrder}
                    quotationTypes={this.state.quotationTypes}
                    CurrentState={this.props.CurrentState}
                  />
                </>
              ) : (
                <>
                  <QuotationRequestDetails
                    show={this.state.showOrderDetailsModal}
                    onHide={this.ModalClose}
                    orderDetail={this.state.selectedOrder}
                    quotationTypes={this.state.quotationTypes}
                  />
                </>
              )}
            </>
          ) : null}
        </div>
      </>
    );
  }
}

export class QuotationRequestDetails extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      loadSpinnerOpen: false,
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",

      assignedTo: this.props.orderDetail.assignedTo,
      requestSeverityType: this.props.orderDetail.requestSeverityType,
      quotationTotalAmount: this.props.orderDetail.quotationTotalAmount,
      quotationAttachmentFile: this.props.orderDetail.quotationAttachmentFile,
      amountReceived: this.props.orderDetail.amountReceived,
      quoteStatus: this.props.orderDetail.quoteStatus,
      quoteStatusNew: "",
      paymentStatus: this.props.orderDetail.paymentStatus,
      notes: this.props.orderDetail.notes,
    };
  }

  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };
  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };
  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
    this.props.onHide();
  };

  SaveOrder = () => {
    var tmporder = this.props.orderDetail;
    tmporder.requestSeverityType = this.state.requestSeverityType;
    tmporder.quotationTotalAmount = this.state.quotationTotalAmount;
    tmporder.quotationAttachmentFile = this.state.quotationAttachmentFile;
    tmporder.amountReceived = this.state.amountReceived;
    tmporder.quoteStatus = this.state.quoteStatusNew;
    tmporder.paymentStatus = this.state.paymentStatus;
    tmporder.assignedTo = this.state.assignedTo;
    tmporder.notes = this.state.notes;

    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(tmporder),
    };

    fetch(
      process.env.REACT_APP_API + "StoreManager/UpdateQuationRequest/",
      requestOptions
    )
      .then((res) => {
        if (res.status != 200) {
          alert("not updated");
          return;
        }
        // Swal.fire({
        //   title: "Status",
        //   text: "Saved Successfully",
        //   timer: 1000,
        // }).then((result) => {});
        this.ShowMessagBox("Status", "Saved Successfully!");
      })
      .catch((error) => {
        //this.HideLoadSpinner();
        alert("Failed" + error);
      });
  };
  handleSubmit = (event) => {
    event.preventDefault();
    this.SaveOrder();
  };
  handleInputChange = (e) => {
    const { name, value } = e.target;

    switch (name.trim()) {
      case "quoteStatusNew":
        this.setState({ quoteStatusNew: value });
        break;
      case "quotationTotalAmount":
        this.setState({ quotationTotalAmount: value });
        break;
      case "amountReceived":
        this.setState({ amountReceived: value });
        break;
      case "paymentStatus":
        this.setState({ paymentStatus: value });
        break;
      case "requestSeverityType":
        this.setState({ requestSeverityType: value });
        break;
      case "assignedTo":
        this.setState({ assignedTo: value });
        break;
      case "notes":
        this.setState({ notes: value });
        break;
    }
  };

  componentDidMount = () => {};

  render() {
    return (
      <div className="container ">
        <Modal
          {...this.props}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="xl"
          backdrop="static"
          keyboard={false}
          dialogClassName="order-modal"
        >
          {this.state.msgboxOpen === true ? (
            <MessageBox
              open={this.state.msgboxOpen}
              title={this.state.msgboxTitle}
              message={this.state.msgboxMessage}
              callback={this.CloseMessagBoxCallback}
            />
          ) : (
            ""
          )}
          <Form
            onSubmit={this.handleSubmit}
            style={{ fontFamily: "Verdana", fontSize: "14px" }}
          >
            <Modal.Header closeButton className="bg-warning">
              <Modal.Title id="contained-modal-title-vcenter">
                Quotation Details
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid ">
              <Row>
                <Col>
                  <div>
                    <h3>Customer Details</h3>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Quotation Request Number"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        disabled
                        value={this.props.orderDetail.quoteNumber}
                      />
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Request Created"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        disabled
                        value={this.context.GetInDateFormat(
                          new Date(this.props.orderDetail.created)
                        )}
                      />
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Customr Name"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        disabled
                        value={this.props.orderDetail.customerName}
                      />
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Contact Number"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        disabled
                        value={this.props.orderDetail.contactNumber}
                      />
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Email-id"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        disabled
                        value={this.props.orderDetail.emailId}
                      />
                    </FloatingLabel>
                  </div>
                  <div className="p-3 border">
                    <h3>Quotation Request</h3>

                    <FloatingLabel
                      controlId="floatingInput"
                      label="Category Name"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        disabled
                        value={this.props.orderDetail.categoryName}
                      />
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Product Name"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        disabled
                        value={this.props.orderDetail.productName}
                      />
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Quantity Required"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        disabled
                        value={this.props.orderDetail.qty}
                      />
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Purpose Of Procurement"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        disabled
                        value={this.props.orderDetail.purposeOfProcurement}
                      />
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Preferred Language"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        disabled
                        value={this.props.orderDetail.preferredLanguage}
                      />
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Delivery Timeline"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        disabled
                        value={this.props.orderDetail.deliveryTimeline}
                      />
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Detailed Requirement"
                      className="mb-3"
                    >
                      <Form.Control
                        as="textarea"
                        type="textarea"
                        height="100px"
                        rows={5}
                        disabled
                        value={this.props.orderDetail.detailedRequirement}
                      />
                    </FloatingLabel>
                  </div>
                </Col>

                <Col>
                  <div className="p-3 border">
                    <h3>Quotation Response</h3>

                    <FloatingLabel
                      controlId="floatingInput"
                      label="Current Status"
                      required
                      className="mb-3"
                    >
                      <Form.Select
                        type="text"
                        // name="quoteStatus"
                        size="lg"
                        disabled
                        value={this.state.quoteStatus}
                        onChange={(e) => this.handleInputChange(e)}
                      >
                        {this.props.quotationTypes.map((m) => (
                          <option value={m.id}>{m.statusName}</option>
                        ))}
                      </Form.Select>
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Next Status"
                      required
                      className="mb-3"
                    >
                      <Form.Select
                        type="text"
                        name="quoteStatusNew"
                        size="lg"
                        value={this.state.quoteStatusNew}
                        onChange={(e) => this.handleInputChange(e)}
                      >
                        <option value=""></option>
                        {this.props.CurrentState == "NewRequest" ? (
                          <>
                            <option value={2}>Start Review</option>
                            <option value={10}>Cancel Quotation</option>
                          </>
                        ) : this.props.CurrentState == "Quotation" ? (
                          <>
                            <option value={3}>QuotationSent</option>
                            <option value={10}>Cancel Quotation</option>
                          </>
                        ) : this.props.CurrentState == "Payment" ? (
                          <>
                            <option value={4}>FullPaymentReceived</option>
                            <option value={5}>PartialPaymentReceived</option>
                            <option value={6}>Start Produce/Making</option>
                            <option value={10}>Cancel Quotation Request</option>
                          </>
                        ) : this.props.CurrentState == "Produce" ? (
                          <>
                            <option value={7}>Ready To Ship</option>
                            <option value={10}>
                              Refunded Amount & Cancel Order
                            </option>
                          </>
                        ) : this.props.CurrentState == "Ship" ? (
                          <>
                            <option value={8}>
                              Fully Paid And Sent For Delivery
                            </option>
                            <option value={10}>
                              Refunded Amount & Cancel Order
                            </option>
                          </>
                        ) : this.props.CurrentState == "Delivery" ? (
                          <>
                            <option value={9}>Delivery and Closed</option>
                          </>
                        ) : this.props.CurrentState == "History" ? (
                          <></>
                        ) : (
                          <></>
                        )}
                        {/* {this.props.quotationTypes.map((m) => (
                          <option value={m.id}>{m.statusName}</option>
                        ))} */}
                      </Form.Select>
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Quotation Total Amount"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        name="quotationTotalAmount"
                        size="lg"
                        value={this.state.quotationTotalAmount}
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Amount Received"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        name="amountReceived"
                        size="lg"
                        value={this.state.amountReceived}
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </FloatingLabel>

                    <FloatingLabel
                      controlId="floatingInput"
                      label="Payment Status"
                      required
                      className="mb-3"
                    >
                      <Form.Select
                        type="text"
                        name="paymentStatus"
                        value={this.state.paymentStatus}
                        onChange={(e) => this.handleInputChange(e)}
                      >
                        <option value={1}>Paid</option>
                        <option value={2}>UnPaid</option>
                        <option value={3}>PartiallyPaid</option>
                      </Form.Select>
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Severity"
                      required
                      className="mb-3"
                    >
                      <Form.Select
                        type="text"
                        name="requestSeverityType"
                        value={this.state.requestSeverityType}
                        onChange={(e) => this.handleInputChange(e)}
                      >
                        <option value=""></option>
                        <option value={1}>HOT</option>
                        <option value={2}>WARM</option>
                        <option value={3}>COLD</option>
                      </Form.Select>
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="AssignTo"
                      required
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        name="assignedTo"
                        value={this.props.assignedTo}
                        onChange={(e) => this.handleInputChange(e)}
                      ></Form.Control>
                    </FloatingLabel>
                  </div>
                </Col>
                <Col>
                  Notes:
                  <Form.Control
                    as="textarea"
                    name="notes"
                    type="text"
                    rows={40}
                    maxLength={1999}
                    value={this.state.notes}
                    onChange={(e) => this.handleInputChange(e)}
                  />
                </Col>
              </Row>
            </Modal.Body>

            <Modal.Footer className="bg-light">
              <Form.Group>
                <Button variant="primary" type="submit" size="lg">
                  Save
                </Button>
              </Form.Group>
              <Button variant="danger" size="lg" onClick={this.props.onHide}>
                Close
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }
}
